import React from 'react'

export default function Branding() {
    return (
        <svg
            version="1.1"
            id="branding"
            viewBox="0 0 2324.5332 2600.4668"
            xmlns="http://www.w3.org/2000/svg"
            ><defs
                id="defs6" />
                {/* <sodipodi:namedview
                id="namedview4"
                pagecolor="#ffffff"
                bordercolor="#666666"
                borderopacity="1.0"
                inkscape:pageshadow="2"
                inkscape:pageopacity="0.0"
                inkscape:pagecheckerboard="0"
                showgrid="false"
                inkscape:zoom="0.32455711"
                inkscape:cx="466.78995"
                inkscape:cy="1301.774"
                inkscape:window-width="1848"
                inkscape:window-height="1016"
                inkscape:window-x="72"
                inkscape:window-y="27"
                inkscape:window-maximized="1"
                inkscape:current-layer="g8" /> */}
                <g
                    id="g8"
                    transform="matrix(1.3333333,0,0,-1.3333333,0,2600.4667)"><g
                        id="g10"
                        transform="scale(0.1)"><g
                            id="g12"
                            transform="scale(1.1965)"><path
                            d="m 13243.4,2662.18 c 1.3,-153.53 -40.6,-193.74 -193,-192.48 -897.8,7.19 -1795.7,3.51 -2693.6,3.51 -897.73,-0.09 -1795.6,4.01 -2693.41,-3.76 -165.85,-1.42 -228.04,26.91 -225.8,213.37 10.91,905.55 9.76,1811.28 0.19,2716.92 -1.87,178.27 48.71,218.97 222.49,218.3 1787.85,-6.77 3575.73,-6.69 5363.63,-0.17 171,0.59 222.9,-35.02 220.8,-215.38 -10.4,-913.32 -8.5,-1826.9 -1.3,-2740.31 z M 7067.41,5773.32 c 9.85,-827.41 -0.83,-1655.07 8.94,-2482.48 2.19,-184.54 -30.96,-251.23 -236.42,-250.4 -2076.55,8.44 -4153.16,7.95 -6229.714,0.59 -196.661,-0.75 -242.729,54.41 -242.066,246.3 7.084,2068.78 4.417,4137.64 4.6,6206.44 0.182,2061.03 2.69,4122.03 -4.062,6183.03 -0.617,186.4 32.883,248.8 236.706,248.2 2771.366,-7.6 5542.756,-7 8314.096,-0.8 186.8,0.4 248.64,-33.7 248.23,-236.6 -7.19,-3161.8 -6.44,-6323.62 -0.92,-9485.36 0.25,-171.58 -35.86,-223.4 -214.88,-219.14 -554.03,13.12 -1108.64,-2.84 -1662.65,9.2 -175.42,3.84 -223.99,-41.54 -221.86,-218.98 z m 6552.29,5.69 c 1.5,183.87 -55.2,213.79 -222.6,212.7 -1194.2,-7.27 -2388.6,2.01 -3582.83,-9.36 -216.63,-2.01 -283.24,37.36 -282.58,271.87 9.2,3262.99 4.27,6525.98 10.87,9788.98 0.42,200.3 -38.87,257.8 -251.32,257.2 -3013.08,-8.2 -6026.23,-8.1 -9039.341,-0.1 C 41.7427,16300.9 -0.679062,16245.1 0.00652944,16044.7 7.51213,13858.9 4.70446,11673.2 4.93952,9487.44 5.17458,7293.92 7.78636,5100.36 0.626827,2906.8 -0.00326472,2713.66 49.2255,2665.94 242.095,2666.61 c 2201.275,7.18 4402.585,2.17 6603.885,8.27 181.08,0.5 259.03,-43.63 227.71,-229.42 -7.69,-45.55 4.37,-94.36 -2.65,-140.16 -24.36,-158.79 29.62,-211.61 199.55,-211.11 2053,6.01 4106.01,5.85 6159.01,0.17 157.9,-0.51 189.4,47.55 188.7,195.4 -6,1163.05 -8.3,2326.19 1.4,3489.25"
                           
                            id="path14" /></g><g
                                id="g16"
                                transform="scale(1.06957)"><path
                            d="m 15858,8998.94 c -610.5,714.32 -1197.5,1405.26 -1790.8,2090.86 -78,90.3 -81.7,146.6 -9.8,241.8 152.1,201.5 285.4,417.2 433.1,622.3 94.7,131.7 100.6,254.6 -1.9,381.3 -10.9,13.5 -21.2,27.8 -30.3,42.6 -148.8,242.5 -462,471.5 -430.6,711.8 31.5,240.9 332.8,447.8 519.1,666.9 422.9,497.4 848,993.1 1311.2,1535.3 0,-2120.2 0,-4184.9 0,-6292.86 z m -3662.7,-390.16 c 522.2,762.82 1022.7,1493.82 1531,2236.02 636,-744.5 1256.3,-1470.5 1910.3,-2236.02 -1171.3,0 -2276,0 -3441.3,0 z m -191.5,555.69 c 0,1987.33 0,3974.63 0,5963.73 74.8,39.3 82.5,-36.5 107.1,-72.4 621.6,-904.5 1238.5,-1812.4 1865.5,-2713.2 97.9,-140.8 115.9,-231.4 8.7,-384.4 -653.8,-933 -1292.9,-1876.2 -1936.9,-2815.97 -14.8,7.42 -29.5,14.83 -44.4,22.24 z m 3613.1,6493.13 c -643.9,-753 -1259.9,-1473.4 -1890.2,-2210.4 -505.9,737 -1001.3,1458.8 -1517.2,2210.4 1144.8,0 2256.6,0 3407.4,0 z m 329.2,436.4 c -1326.8,0.5 -2653.6,0.5 -3980.3,0.2 -363.5,-0.1 -378.3,-12.6 -378.7,-388.7 -1.7,-1187.1 -0.7,-2374.3 -0.7,-3561.4 -0.5,0 -1.1,0 -1.6,0 0,-1187.1 -0.1,-2374.26 0.1,-3561.4 0,-360.8 33.9,-396.99 381,-397.26 1318,-0.85 2636,-1.03 3954.1,-0.66 339.5,0.19 379.4,38.99 379.4,372.77 0.7,2391.75 0.8,4783.45 0.1,7175.15 -0.1,335 -26.8,361.2 -353.4,361.3"
                           
                            id="path18" /></g><path
                        d="m 11627.2,934.402 c -718.8,-4.3 -1437.6,-1.601 -2156.37,-1.699 -1017.51,0 -2035.02,0 -3052.53,0 -1680.3,0 -3360.59,-0.101 -5040.89,0.195 -74.59,0 -150,-3.496 -223.62,5.903 C 989.969,959.5 904.531,1072.4 895.57,1222.4 c -8.343,139.6 64.789,255.7 210.61,292.7 88.77,22.5 183.85,27.8 276.1,27.8 3379.25,1.3 6758.52,0.9 10137.72,1.8 171.7,0.1 352,5.6 436.2,-176.8 109.6,-237.3 -39.2,-431.701 -329,-433.498 z M 13095.6,1397 c -168,2.2 -336.1,-0.8 -504.1,2.2 -69.3,1.2 -146.1,-24.1 -185.2,75.6 -177.7,452.8 -550.9,519.5 -981.7,518.7 -3323.42,-6.4 -6646.89,-3.7 -9970.35,-5.4 -139.32,-0.1 -281.83,-0.7 -417.37,-28.2 C 575.891,1866.6 306.984,1371.1 502.711,945.602 728.984,453.699 1090.46,115.398 1656.1,40.5 2214.58,-33.3984 2774.88,20.2031 3334,2 c 130.09,-4.19922 268.53,11.5 272.48,167.402 4.82,190.2 -157.65,168.598 -287.29,168.797 -420.11,0.403 -840.23,-2 -1260.3,1.301 -200.04,1.602 -399.16,17.699 -572.03,145.703 1951.04,0 3902.1,0 5853.15,0 1419,0 2837.99,2.199 4256.99,-2.305 255.6,-0.796 539.3,26.204 641.9,255.704 167.8,375.598 455.2,327.698 752.6,322.998 37.3,-0.5 75,-1.9 111.9,2.4 109.2,12.9 170.7,73.2 162.5,186 -7.5,101.5 -74.5,145.7 -170.3,147"
                       
                        id="path20" /><path
                        d="m 5720.65,11254.5 c -1083.27,-0.1 -2166.53,0.4 -3249.79,-0.6 -162.33,-0.1 -349.68,-13.8 -358.67,206.6 -9.62,235.8 178.64,243.4 356.72,243.3 2157.19,-0.9 4314.38,-0.6 6471.57,-0.7 28.01,0 56.03,-0.7 84.04,-0.4 164.06,2 280.6,-64.7 275.22,-240.8 -5.04,-164.8 -122.99,-209.4 -273.28,-208.4 -494.92,3 -989.87,1 -1484.81,1 -607,0 -1214,0 -1821,0"
                       
                        id="path22" /><path
                        d="m 5721.15,9694.11 c 1074.05,-0.12 2148.1,-0.45 3222.15,0.24 161.88,0.1 348.52,16.64 354.75,-209.35 6.58,-238.3 -180.16,-240.8 -357.77,-240.7 -2157.44,1.2 -4314.88,1 -6472.31,0.4 -176.92,-0.1 -363.94,4 -355.62,243.1 6.96,200.07 171.07,207.92 330.62,207.6 1092.72,-2.24 2185.45,-1.18 3278.18,-1.29"
                       
                        id="path24" /><path
                        d="m 5693.04,13256.2 c -1044.94,-0.1 -2089.87,-0.4 -3134.8,0.4 -93.1,0.1 -187.16,-0.1 -279.06,12.3 -106.16,14.5 -163.37,90.3 -169.98,192.3 -7.58,116.9 52.07,202.5 168.84,220.3 109.66,16.6 222.8,13.6 334.42,13.6 2099.19,0.9 4198.38,1.1 6297.57,0.9 74.33,0 149.79,0.4 222.75,-11.4 121.79,-19.8 171.99,-106.2 166.8,-221.4 -4.63,-102.5 -55.3,-180.7 -164.76,-195.1 -82.75,-10.9 -167.28,-11.3 -251.01,-11.3 -1063.59,-0.7 -2127.18,-0.5 -3190.77,-0.6"
                       
                        id="path26" /><path
                        d="m 4322.72,7689.3 c 625.41,0 1250.82,-1.3 1876.22,0.9 158.7,0.5 323.97,-6.7 329.16,-209 5.59,-218.3 -166,-235.5 -336.01,-235.5 -1250.81,0.6 -2501.63,-0.2 -3752.44,0 -144.31,0 -303.91,4.8 -327.05,185.9 -27.91,218.4 126.03,259.1 305.9,258.5 634.74,-2 1269.48,-0.7 1904.22,-0.8"
                       
                        id="path28" /><path
                        d="m 12210.2,3427.6 c -727,-0.3 -1454,-0.3 -2181,-0.3 -55.93,0 -111.89,-1.4 -167.77,0.3 -147.35,4.5 -260.83,60 -263.54,224.3 -2.73,165.5 103.86,223.7 256.55,223.6 1575.16,-0.6 3150.36,-0.7 4725.46,-0.1 149.9,0.1 261.8,-48 263.2,-216.7 1.7,-187.8 -131.5,-229.6 -284.1,-229.9 -782.9,-1.8 -1565.9,-0.8 -2348.8,-1.2"
                       
                        id="path30" /><g
                            id="g32"
                            transform="scale(1.0707)"><path
                            d="m 2726.48,14660.6 c -327.94,8.7 -608.45,303.8 -598.99,630.1 9.56,329.5 302.6,602.3 635.06,591.1 322.59,-10.8 583.67,-287.4 579.8,-614.2 -4.01,-339.6 -284.27,-615.8 -615.87,-607 z m 13.83,1636.3 c -567.83,3.1 -1033.95,-461.8 -1031.58,-1028.9 2.31,-552 459.26,-1014.3 1014.18,-1026.1 565.26,-12 1031.37,447.7 1036.52,1022.3 5.19,579.1 -439.39,1029.6 -1019.12,1032.7"
                           
                            id="path34" /></g><path
                        d="m 10172.9,4948.2 c -209.39,9.3 -385.05,195.1 -381.57,403.4 3.47,207.7 188.53,390.1 394.77,388.9 214.5,-1.1 385.9,-172.2 389.5,-388.7 3.7,-225.4 -183.9,-413.4 -402.7,-403.6 z m 18.2,1236.7 c -471.96,1.1 -854.94,-382.5 -849.08,-850.3 5.72,-456.5 381.01,-828.3 841.08,-833.3 468.9,-5.1 844.5,375.3 842.2,853 -2.2,462.1 -371.2,829.6 -834.2,830.6"
                       
                        id="path36" /></g></g></svg>

    )
}