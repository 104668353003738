import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { socialIconList } from '../../utitlities/IconRenderMap'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    svg: {
        padding: '10px',
        fill: theme.palette.primary.main,
        '&:hover': {
            fill: theme.palette.secondary.main
        },
        "& svg": {
            height: '20px'
        }
    },
    buttonFloat: {
        padding: '8px',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            border: `1px solid ${theme.palette.secondary.main}`,
        }
    }
}))

export default function SocialLinksRender({ list = [], spacing = 0, isFloat = false, buttonStyles = {} }) {
    const classes = useStyles()
    return <Grid container direction={isFloat ? 'column' : 'row'} spacing={isFloat ? 1 : spacing} >
        {
            list.map(item => {
                return <Grid item key={item.id}>
                    <IconButton style={buttonStyles} className={clsx(classes.svg, { [classes.buttonFloat]: isFloat })} id={item.id} href={item.url} target="_blank" rel='nofollow'>
                        {socialIconList[item.id]}
                    </IconButton>
                </Grid>
            })
        }
    </Grid>
}