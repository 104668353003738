import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'
// import FaviconPng from '../../static/favicon.png'
// import FaviconSvg from '../../static/favicon.svg'

function Seo({ description = '', schemaTag = [], title = '' }) {
    let data = useStaticQuery(graphql`
    query SiteMetaQuery {
        site {
          siteMetadata {
            title
            siteName
          }
        }
      }
    `)
    // const location = useLocation()
    // const baseUrl = location.origin
    // const logoUrl = `${baseUrl}/Logo.svg`
    // const url = `${baseUrl}${location.pathname}${location.search}`
    // const canoncialUrl = `${baseUrl}${location.pathname}${location.search}`
    // const twitterHandle = ''
    // const fbAdminID = ''
    const siteName = data.site.siteMetadata.siteName
    // let validSchemaTags = []
    // for (let tag of schemaTag) {
    //     if (!!tag.SchemaJSON) {
    //         validSchemaTags.push(tag.SchemaJSON)
    //     }
    // }
    // const linkList = [
    //     {
    //         rel: "canonical",
    //         href: canoncialUrl
    //     }
    // ]
    const metaList = [
        {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0"
        },
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: description,
        },
        // {
        //     property: 'og:image',
        //     content: logoUrl
        // },
        // {
        //     property: "og:url",
        //     content: url
        // },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: "og:site_name",
            content: siteName
        },
        {
            name: `twitter:card`,
            content: `summary_large_image`,
        },
        // {
        //     name: `twitter:creator`,
        //     content: twitterHandle,
        // },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: description,
        },
        // {
        //     name: "twitter:site",
        //     content: twitterHandle
        // },
        // {
        //     name: "twitter:image:src",
        //     content: logoUrl
        // },
        // {
        //     property: "fb:admins",
        //     content: fbAdminID
        // }
        {
            name: "facebook-domain-verification",
            content: "1k6jeblqpmawk500pbw6anjcu2eie3"
        },
        {
            name: "google-site-verification",
            content: "btBmRq7lOqUA9Hg8mfqA0GVXy30KafZdXsR_r3p-SD8"
        }
    ]
    const validMeta = metaList.filter(item => !!item.content)
    // const validLink = linkList.filter(item => !!item.href)
    // const faviconLinks = [
    //     { rel: "icon", href: FaviconPng, size: '32x32', type: 'image/png' },
    //     { rel: "icon", href: FaviconSvg, type: "image/svg+xml" }
    // ]
    return (
        <Helmet
            title={!!title ? title : data.site.siteMetadata.title}
            // link={[...validLink]}
            meta={validMeta}>
            {/* {
                validSchemaTags.map(schema => (
                    <script type="application/ld+json">
                        {JSON.stringify(schema)}
                    </script>
                ))
            }*/}
            <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/shell.js"></script>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25503956.js"></script>
        </Helmet>
    )
}

export default Seo
