

const palettePrimaryColors = {
    light: '#eef6ff',
    main: "#224674",
    dark: "#001f48"
}

const paletteSecondaryColors = {
    light: "#ffc446",
    main: '#f29300',
    dark: "#b96500"
}

const primaryTextColors = '#224674'

export const hoverShade1 = "#e1efff"
export const hoverShade2 = "#d3e8ff"

const secondaryTextColors = '#000000'
export const generalTextColor = '#6c6e75'
export const paletteConfig = {
    primary: palettePrimaryColors,
    secondary: paletteSecondaryColors,
    text: {
        primary: primaryTextColors,
        secondary: secondaryTextColors
    }
}

export const contentMaxWidth = '1200px'
export const containerMaxWidth = '1400px'
export const primaryButton = 'primary'
export const secondaryButton = 'secondary'


export const facebookConstant = 'facebook'
export const linkedinConstant = 'linkedin'
export const instagramConstant = 'instagram'
export const whatsappConstant = 'whatsapp'
export const twitterConstant = 'twitter'
export const snapchatConstant = 'snapchat'
export const youtubeConstant = 'youtube'
export const quoraConstant = 'quora'
export const googlePartner = 'google-partner'
export const facebookPartner = 'facebook-partner'

export const socialMediaConstant = "social-media-marketing"
export const semConstant = "sem-seo"
export const seoConstant = "seo"
export const photoConstant = "commercial-photography"
export const videoConstant = 'commercial-videography'
export const influenceConstant = "influencer-marketing"
export const webConstant = "web-development"
export const brandingConstant = 'brand-content'
export const traniningConstant = 'marketing-training'

export const portalId = '25503956'
export const portfolioServices = [socialMediaConstant,webConstant,influenceConstant,photoConstant,videoConstant,brandingConstant]

export const contactFormId = '434202dd-7f65-4c23-af68-0ee9f0f2dd60'
export const contactFormDivId = 'contact-form'


export const getStartedFormId = "4bc23e29-07e2-4e0c-ab9d-7c0f351998a0"
export const getStartedFormDivId = 'get-started'

export const popUpFormId = "0e5bd254-a748-4e8b-be5b-3c8e085cee82"
export const popUpFormDivId = 'free-popup'

export const mainLeadFormId = '9327bec5-08ac-4358-bef5-c6cf69c5f399'
export const mainLeadFormDivId = 'main-lead'

export const dialogFormId = '9327bec5-08ac-4358-bef5-c6cf69c5f399'
export const dialogFormDivId = 'main-lead-dialog'


export const marketingAuditFormId = '3ed6aa9d-9b68-40c0-a7b2-c2941fe439b5'
export const marketingAuditFormDivId = 'marketing-audit-form'

export const hubspotSubmitEndPoint = 'https://api.hsforms.com/submissions/v3/integration/submit'

// export const freeDownloadFormId = "50d8649c-7107-4710-bedb-1417df1e816c"
export const freeDownloadFormId = "1c9c034a-0250-49c9-a22a-26d0672c05a8"

export const freeDownloadFormDivId = 'freeDownloadForm'