import React from 'react'

export default function Training() {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2029.7 2208.9" >
               <style type="text/css">
                    {`  .stT0{fill:#214675;}`}
               </style>
               <g>
                    <path class="stT0" d="M1518.1,2103.7c-3,0-6,0-9,0l-8.3-0.1c-44.5-0.4-90.6-0.8-136.2-10.9l-5.2-1.2c-20.3-4.5-41.2-9.2-61.6-16.2
		c-8.2-2.8-15-6.9-22-11.6c-1.3,1.2-2.9,1.8-4.7,1.8c0,0,0,0-0.1,0c-14.7,10.5-32,15.4-47.2,19.2c-66.2,16.6-131.4,18.8-180.8,18.8
		c-4.7,0-9.4,0-14-0.1c-53.2-0.4-99.8-4.7-142.5-13c-24.1-4.7-51.3-10-74.3-27.8c-25,18.4-53.9,23.7-81.8,29
		c-41.1,7.7-85.6,11.5-136.1,11.7c-6.2,0-12.5,0.1-18.9,0.1c-42.6,0-88.1-1.3-132.1-10.6c-22.8-4.8-47.1-10.3-70.7-18.4
		c-36.9-12.7-50.5-38.8-40.4-77.7c12.6-48.2,41.9-88.8,87.1-120.8c18.8-13.3,38.9-24,60.3-35.4c0,0,1.4-0.8,1.9-1
		c-36.4-41.8-52.6-89.7-48.1-142.3c2.3-27.5,14.4-55.3,34.1-78.4c19.2-22.5,44.4-39.2,71-47.1c14.3-4.2,29-6.3,43.7-6.3
		c32,0,62.6,10,88.5,28.9c37.6,27.4,59.8,69.2,60.9,114.7c1.2,48.4-15.3,92.3-49.1,130.6c31.5,13.8,57.8,30.3,80.2,50.2
		c19.9,17.6,36.6,38.2,49.8,61.3c13.1-23.1,29.6-43.3,50-61.4c22.3-19.8,48.6-36.2,80-50c-35.5-40.4-51.8-86.3-48.6-136.4
		c2.4-37.5,19-72.3,46.8-98c27.7-25.6,63.9-39.7,101.9-39.7c0.8,0,1.6,0,2.3,0c43.3,0.6,84.1,19.5,111.8,51.9
		c27.8,32.5,40.2,75.9,34,119.2c-5.6,39-21.5,73.6-47.3,103c31,13.2,57.3,29.7,80.1,50.3c21.5,19.5,38,39.5,50.2,60.9
		c13.1-22.8,29.6-43.1,48.9-60.4c22.6-20.3,49.1-36.9,80.8-50.7c-27.8-31.8-44.2-69.6-48.7-112.7c-6.9-66.2,38.8-135.4,102-154.2
		c16.1-4.8,32.4-7.2,48.4-7.2c25.7,0,50.7,6.4,72.3,18.4c34.9,19.5,60.4,53,71.6,94.5c11.6,42.8,3.9,86.8-22.9,130.9
		c-6.6,10.8-14.3,20.7-22.1,30.2c58.2,23.8,102.8,62.6,132.8,115.3c8.4,14.7,12.7,30.5,16.9,45.7l0,0.1c0.6,2.1,1.1,4.1,1.7,6.2
		c6.9,24.7-3.2,47.9-25.1,57.9c-21.8,9.9-44.4,20.2-69.1,25.5C1618.2,2099.2,1571.4,2103.7,1518.1,2103.7z M1291.2,2020.7
		c1.2,0.8,2.3,1.6,3.3,2.3c6.4,4.5,12.4,8.8,17.8,10.6c17.9,6.2,37.5,10.6,56.4,14.8l5.6,1.2c41,9.1,84.6,9.5,126.8,9.9l8.5,0.1
		c2.8,0,5.7,0,8.4,0c50.3,0,94.2-4.1,134.2-12.6c19.9-4.2,40.2-13.5,59.8-22.4l0.2-0.1c0.7-0.3,2.4-1.1,1-6
		c-0.6-2.1-1.2-4.3-1.8-6.4c-3.6-13.2-7.1-25.7-12.7-35.7c-26.7-46.9-67.6-80.7-121.6-100.3c-4.6-1.7-8.9-3.6-12.8-5.3l-1.1-0.5
		c-1.2-0.5-2.4-1.1-3.6-1.6l-17.9-7.6c-2-0.9-3.5-2.6-4-4.7c-0.5-2.1-0.1-4.3,1.3-6.1l12-15.4c4.6-5.9,9.1-11.4,13.6-16.8
		c9-10.9,17.5-21.2,24.1-32c20.3-33.3,26.4-65.8,18.1-96.5c-8.1-29.9-26-53.9-50.5-67.5c-15.3-8.5-32.4-12.8-50.9-12.8
		c-11.8,0-23.8,1.8-35.8,5.4c-43.1,12.8-75.5,62-70.7,107.4c4.2,40.3,20.7,73.4,50.5,101.1c3.3,3.1,5.6,6.3,7.5,8.9l0.1,0.2
		c0.7,0.9,1.3,1.8,1.9,2.6l12.8,16.6c1.4,1.8,1.8,4.1,1.2,6.3c-0.6,2.2-2.3,3.9-4.4,4.6l-19.7,6.8c-82,28.4-131.2,74.1-154.7,144
		L1291.2,2020.7z M830.9,2019.9c15.3,17.9,39.1,22.6,64.1,27.4c40,7.8,83.9,11.8,134.4,12.2c4.5,0,9.1,0.1,13.7,0.1
		c46.9,0,108.5-2,170-17.4c16.2-4.1,32.7-9,40-20.1l0.8-1.2l-2.3-8.9c-8.8-33.4-27.1-61.8-57.6-89.5c-25.4-23-56.8-40.3-96.1-52.9
		l-21.9-7c-2.3-0.7-4-2.5-4.6-4.8c-0.6-2.3-0.1-4.7,1.5-6.5l15.3-17.2c1.2-1.4,2.2-2.6,3.1-3.6l0.1-0.2c1.9-2.3,4-4.6,6.5-7
		c27.9-25.8,44.1-56.2,49.3-92.9c4.4-31.2-4-61.2-23.9-84.4c-19.7-23.1-47.8-36-79.1-36.5c-0.5,0-1,0-1.5,0
		c-55.3,0-101.4,42.4-104.9,96.5c-2.8,43.1,13,80.9,48.2,115.6c2,2,4,4,6.3,6.3l4.5,4.6l16.6,16.9c1.7,1.7,2.4,4.2,1.8,6.5
		s-2.2,4.2-4.5,5l-22.4,7.8c-82.8,29-132.1,74.5-155,143.2L830.9,2019.9z M523.7,1820c0.5,0.2,1,0.4,1.3,0.5l0.3,0.1
		c1.5,0.6,3.4,1.3,5.4,2.2l26.2,12.4c2.4,1.1,3.9,3.5,4,6.1c0.1,2.6-1.3,5.1-3.6,6.4l-25.3,14.2c-11.2,6.3-22.1,12.1-31.7,17.3
		c-20.9,11.2-39,20.8-55.6,32.5c-36.5,25.9-60,58.2-69.9,96c-2.3,8.7-2.6,14-1.1,17c1.4,2.9,5.5,5.4,13.2,8
		c21.3,7.3,44.1,12.4,65.6,17c40,8.5,82.9,9.7,123.3,9.7c6.2,0,12.2,0,18.2,0c47.8-0.2,89.7-3.8,128.2-10.9
		c27.7-5.2,54-10.1,72.1-28.3l-2.7-8.2c-23-68.7-72.3-114.2-155.1-143.2l-22.2-7.8c-2.3-0.8-4-2.7-4.5-5s0.1-4.8,1.8-6.5l16.4-16.8
		c1.6-1.6,3-3.1,4.3-4.5l0.2-0.2c2.6-2.7,4.9-5,7.2-7.4c32.6-32.3,48.6-69.4,47.6-110.5c-0.8-31.7-16.4-60.9-42.8-80.2
		c-18.3-13.4-40-20.4-62.6-20.4c-10.5,0-21,1.5-31.2,4.5c-38.7,11.4-70.4,48.8-73.7,86.9C473.5,1745.6,488.7,1784.6,523.7,1820z"/>
                    <path class="stT0" d="M582.3,1572.3c30.1,0,59.5,9.4,84.4,27.6c35.8,26.1,57,65.9,58,109.2c1.2,49.6-16.9,94.4-53.9,133
		c34.8,14.3,63.5,31.7,87.4,52.9c22.6,20,40.8,43.8,54.5,70.8c13.6-27.2,31.6-50.7,54.6-71c23.8-21.1,52.5-38.4,87.2-52.7
		c-38.8-40.8-56.8-87.3-53.5-138.5c4.8-73.9,66.8-131.2,141.8-131.2c0.7,0,1.4,0,2.2,0c41.4,0.6,80.3,18.6,106.6,49.4
		c26.5,31,38.3,72.4,32.4,113.7c-5.8,41-23.5,76.8-52.4,106.6c34.3,13.4,63.1,30.8,87.4,52.8c24.6,22.3,42.5,45.3,54.8,70.5
		c13.7-26.8,31.6-50.3,53.7-70c24.1-21.6,53-39.1,88.1-53.3c-30.9-32.1-49-71.1-53.7-116c-6.6-63,36.9-128.9,97-146.8
		c15.5-4.6,31.1-6.9,46.4-6.9c24.5,0,48,5.9,68.9,17.5c33.3,18.6,57.5,50.6,68.2,90.2c11.1,40.9,3.6,83.1-22.2,125.4
		c-7.9,13-17.7,24.8-27.2,36.3c-0.2,0.2-0.3,0.4-0.5,0.6c61.1,22.8,107.6,61.8,138.3,115.8c8,14,12.2,29.4,16.3,44.2
		c0.6,2.1,1.1,4.2,1.7,6.2c5.9,21.2-2.6,41.2-21.3,49.7c-21.5,9.8-43.7,19.9-67.6,25c-42.5,9-89,13.4-141.7,13.4c-2.9,0-5.9,0-8.9,0
		l-8.3-0.1c-44.2-0.4-89.8-0.8-134.7-10.8l-5.2-1.2c-20.1-4.5-40.8-9.1-60.9-16c-9.9-3.4-18.1-9.1-26-14.7c-0.7,0.7-1.4,1.3-2.2,1.9
		l-0.9,2.6l-0.4-1.5c-14.1,11.5-32.1,16.9-48.6,21c-62.6,15.7-124.9,18.5-179.1,18.5c-4.7,0-9.3,0-13.9-0.1
		c-52.8-0.4-98.9-4.6-141.2-12.8c-25.1-4.9-53.1-10.3-75.5-29.9c-24.7,20.1-54.4,25.6-83.3,31c-40.7,7.6-84.8,11.4-134.8,11.6
		c-6.2,0-12.5,0.1-18.8,0.1c-41.3,0-86.5-1.1-130.7-10.5c-22.6-4.8-46.6-10.2-69.9-18.2c-33.3-11.5-45-34.1-35.9-69.3
		c12.1-46.5,40.5-85.9,84.3-116.9c18.4-13,38.4-23.7,59.5-35c3.2-1.7,6.5-3.5,9.8-5.2c-39.3-41.9-56.9-90.2-52.4-143.6
		c2.2-26.1,13.7-52.5,32.4-74.4c18.3-21.5,42.3-37.4,67.7-44.9C554.4,1574.3,568.4,1572.3,582.3,1572.3 M576,2066.4
		c6.2,0,12.3,0,18.3,0c48.2-0.2,90.5-3.8,129.4-11.1c30-5.6,58.3-10.9,78.1-32.7l0.7-0.8l-4.1-12.1
		c-23.7-70.9-74.4-117.8-159.4-147.6l-22.2-7.8l16.4-16.9c1.6-1.6,3-3.1,4.4-4.5c2.7-2.8,5-5.1,7.4-7.5
		c34-33.7,50.7-72.6,49.7-115.7c-0.8-33.9-17.5-65.1-45.7-85.7c-19.7-14.3-42.9-21.8-66.7-21.8c-11,0-22.2,1.6-33.2,4.8
		c-41.4,12.2-75.2,52.2-78.7,93.1c-4,47.4,12.1,88.5,49.4,125.7c0.7,0.3,2.2,0.9,3.2,1.3c1.5,0.5,3.2,1.2,4.9,1.9l26.2,12.4
		l-25.3,14.2c-11,6.2-21.5,11.8-31.6,17.2c-21.2,11.3-39.5,21.1-56.3,33c-37.9,26.8-62.3,60.5-72.6,100
		c-5.3,20.2-1.8,27.1,16.6,33.4c21.7,7.5,44.7,12.6,66.4,17.2C492.6,2065.4,536.1,2066.4,576,2066.4 M1043.1,2066.5
		c52.3,0,112.3-2.7,171.8-17.6c17.6-4.4,35.5-9.8,44.1-23.1l2.5-3.8l-3.1-11.9c-9.2-34.8-28.1-64.3-59.7-92.9
		c-26.1-23.7-58.4-41.5-98.6-54.4l-21.9-7l15.3-17.2c1.3-1.5,2.4-2.8,3.4-3.9c2-2.4,3.8-4.4,5.9-6.4c29.2-26.9,46-58.7,51.5-97
		c4.7-33.2-4.3-65.2-25.5-89.9c-21-24.6-51-38.5-84.3-38.9c-0.5,0-1.1,0-1.6,0c-59.2,0-108.1,45-111.9,103.1
		c-2.9,45.2,13.5,84.8,50.3,121.1c2.2,2.1,4.3,4.3,6.7,6.8c1.2,1.2,2.6,2.6,4.1,4.1l16.7,16.9l-22.4,7.8
		c-85,29.7-135.7,76.6-159.3,147.6l-3.9,11.6l1.5,1.9c16.9,20.8,42.3,25.7,69.1,31c40.4,7.9,84.8,11.9,135.7,12.3
		C1033.9,2066.5,1038.5,2066.5,1043.1,2066.5 M1518,2066.7c50.7,0,95.1-4.2,135.6-12.8c20.7-4.4,41.4-13.8,61.4-22.9
		c6.8-3.1,6-10.3,4.9-14.3c-0.6-2.1-1.2-4.2-1.8-6.4c-3.8-13.7-7.3-26.6-13.4-37.3c-27.6-48.4-69.7-83.2-125.2-103.4
		c-4.4-1.6-8.7-3.5-12.4-5.2c-1.6-0.7-3.1-1.4-4.7-2l-18-7.6l12-15.4c4.5-5.8,9.1-11.3,13.4-16.6c9.2-11.1,17.9-21.6,24.7-32.8
		c21.3-35,27.7-69.3,18.8-101.9c-8.6-31.8-27.7-57.3-53.9-71.8c-16.3-9.1-34.9-13.7-54.3-13.7c-12.4,0-25.1,1.9-37.8,5.7
		c-46.1,13.7-80.8,66.3-75.7,114.9c4.4,42.1,21.6,76.6,52.7,105.5c2.8,2.6,4.8,5.4,6.5,7.9c0.7,1,1.4,2,2.2,3l12.8,16.5l-19.7,6.8
		c-84.2,29.2-134.8,76.3-159,148.3l-4.2,12.4c2.6,1.8,5.1,3.5,7.6,5.3c6.8,4.8,13.2,9.4,19.5,11.5c18.4,6.4,38.3,10.8,57.5,15.1
		l5.2,1.2c41.8,9.3,85.8,9.7,128.4,10l8.3,0.1C1512.3,2066.7,1515.2,2066.7,1518,2066.7 M582.3,1558.3c-15.3,0-30.7,2.2-45.7,6.6
		c-27.9,8.2-54.4,25.7-74.4,49.2c-20.6,24.1-33.2,53.4-35.7,82.3c-4.4,51.8,10.4,99.1,44.2,140.8c-19.4,10.4-37.9,20.5-55.3,32.8
		c-46.6,33-76.8,75-89.8,124.8c-5.5,21-4.4,39,3.1,53.5c7.6,14.7,21.7,25.7,41.8,32.6c24.1,8.3,48.5,13.8,71.6,18.6
		c44.6,9.5,90.5,10.8,133.6,10.8c6.4,0,12.8,0,18.9-0.1c50.9-0.2,95.8-4.1,137.3-11.8c26-4.8,55-10.2,80.5-27.2
		c23.2,16.4,49.5,21.5,73.1,26.1c43.1,8.4,90.1,12.7,143.7,13.1c4.9,0,9.5,0.1,14,0.1c49.8,0,115.6-2.2,182.5-19
		c16.4-4.1,33.3-9.3,48-19.2c0.7-0.1,1.3-0.3,1.9-0.5c5.8,3.7,12.5,7.4,20.1,10.1c20.8,7.2,41.9,11.9,62.4,16.5l5.2,1.2
		c46.3,10.3,92.7,10.7,137.6,11.1l8.3,0.1c2.7,0,5.7,0,9,0c53.8,0,101.1-4.5,144.6-13.7c25.5-5.4,48.4-15.8,70.5-25.9
		c12.2-5.6,21.8-15.3,26.9-27.3c5.1-11.9,5.8-25.4,2-38.9c-0.6-2-1.1-4.1-1.7-6.1l0-0.1c-4.3-15.6-8.7-31.8-17.6-47.3
		c-29.3-51.5-72.2-90-127.7-114.6c5.9-7.5,11.7-15.3,16.8-23.8c27.9-45.7,35.8-91.6,23.7-136.3c-11.7-43.3-38.3-78.4-74.9-98.8
		c-22.7-12.6-48.8-19.3-75.7-19.3c-16.7,0-33.7,2.5-50.4,7.5c-32.2,9.6-61,31.6-81.2,62.1c-20.1,30.4-29.3,65.8-25.7,99.5
		c4.3,41.4,19.2,78.1,44.4,109.4c-28.7,13.3-53.1,29.2-74.2,48.1c-17.1,15.3-31.9,32.7-44.3,52.2c-11.8-18.3-26.9-35.7-45.5-52.6
		c-21.2-19.2-45.3-35-73.4-47.8c23.2-28.8,37.6-62.1,42.9-99.4c6.4-45.3-6.5-90.7-35.6-124.7c-28.9-33.9-71.6-53.7-117-54.3
		c-0.9,0-1.7,0-2.4,0c-39.8,0-77.7,14.8-106.7,41.6c-29.1,26.9-46.5,63.4-49,102.7c-3.2,49.1,11.7,94.2,44.3,134.3
		c-28.4,13.3-52.5,28.9-73.4,47.3c-18,15.9-32.9,33.4-45.3,53.1c-12.5-19.7-27.6-37.4-45.1-52.9c-20.9-18.6-45.1-34.2-73.5-47.5
		c30.8-37.9,45.9-81,44.7-128.2c-1.2-47.7-24.4-91.5-63.8-120.2C647.8,1568.8,615.8,1558.3,582.3,1558.3L582.3,1558.3z M527.6,1814
		c-32.8-33.6-46.9-70.4-43.4-112.6c3-34.9,33.1-70.4,68.7-80.8c9.6-2.8,19.5-4.3,29.3-4.3c21.2,0,41.4,6.6,58.5,19.1
		c24.7,18,39.2,45.3,40,74.8c1,39.1-14.4,74.5-45.5,105.4c-2.4,2.4-4.7,4.8-7.4,7.5l-0.2,0.2c-1.3,1.4-2.7,2.8-4.3,4.5l-16.4,16.9
		c-3.3,3.4-4.7,8.3-3.6,13c1.1,4.7,4.5,8.4,9,10l22.2,7.8c80.6,28.2,128.5,72.4,150.8,138.8l1.3,3.9c-16.4,14.3-39.2,18.6-65.3,23.4
		c-38.1,7.1-79.6,10.6-126.9,10.8l-0.1,0c-5.9,0-12,0-18.1,0c-37.3,0-80.6-0.7-121.9-9.5c-21.3-4.5-43.8-9.5-64.8-16.8
		c-8-2.7-9.2-4.5-9.2-4.5c0-0.1-0.9-2.3,1.6-12.1c9.4-36.2,32-67.2,67.2-92.1c16.2-11.5,34.1-21,54.8-32.1
		c9.6-5.1,20.6-11,31.8-17.3l25.3-14.3c4.6-2.6,7.3-7.5,7.1-12.7c-0.2-5.2-3.3-9.9-8-12.2l-26.2-12.4c-2.2-1.1-4.3-1.8-6-2.4l0,0
		L527.6,1814L527.6,1814z M838.8,2018.1l1.3-4c22.2-66.5,70-110.6,150.6-138.8l22.4-7.8c4.5-1.6,7.9-5.4,9-10.1
		c1.1-4.7-0.3-9.6-3.7-13l-16.7-16.9c-0.9-0.9-1.8-1.8-2.6-2.6l-1.5-1.5l-0.5-0.5c-2.3-2.3-4.3-4.4-6.4-6.4
		c-33.7-33.2-48.8-69.2-46.1-110.2c3.3-50.5,46.3-90,97.9-90c0.5,0,1,0,1.4,0c29.2,0.4,55.4,12.5,73.8,34
		c18.5,21.7,26.4,49.7,22.3,78.9c-5,35-20.4,64-47.1,88.7c-2.8,2.6-5,5.2-7.1,7.6l-0.3,0.3c-0.8,1-1.8,2.1-2.9,3.4l-15.3,17.2
		c-3.1,3.5-4.3,8.4-3,13c1.2,4.6,4.7,8.2,9.2,9.6l21.9,7c38.3,12.3,68.9,29.1,93.5,51.4c29.4,26.7,47.1,54,55.5,86.1l1.5,5.7
		c-6.5,8.2-21.3,12.5-34.9,15.9c-60.8,15.3-121.9,17.2-168.4,17.2c-4.4,0-8.8,0-13.6-0.1c-50-0.4-93.5-4.3-133.1-12
		C873,2035.9,852.6,2031.9,838.8,2018.1L838.8,2018.1z M1299.5,2018l0.8-2.5c22.7-67.6,70.5-111.9,150.3-139.6l19.7-6.8
		c4.3-1.5,7.5-4.9,8.8-9.2c1.3-4.3,0.4-9-2.3-12.6l-12.8-16.5c0,0-0.1-0.1-0.1-0.1c-0.5-0.7-1.1-1.4-1.7-2.3l-0.1-0.2
		c-1.9-2.7-4.6-6.4-8.4-10c-28.5-26.5-44.3-58.1-48.3-96.7c-4.3-41.6,26.4-88.3,65.8-100c11.3-3.4,22.7-5.1,33.8-5.1
		c17.3,0,33.3,4,47.5,12c22.8,12.7,39.6,35.2,47.2,63.3c7.8,28.8,2,59.4-17.3,91c-6.3,10.3-14.7,20.5-23.5,31.2l0,0
		c-4.5,5.4-9.1,10.9-13.7,16.9l-12,15.4c-2.7,3.4-3.6,7.9-2.5,12.1c1.1,4.2,4.1,7.7,8.1,9.4l18,7.6c1.1,0.5,2.3,1,3.4,1.5l1.1,0.5
		l0.1,0c3.9,1.7,8.4,3.7,13.2,5.5c52.4,19,92,51.7,117.9,97.2c5.3,9.2,8.6,21.3,12.1,34.1c0.5,1.9,1,3.7,1.5,5.6
		c-18.4,8.3-37.2,16.7-55.4,20.6c-39.5,8.4-82.9,12.5-132.7,12.5c-2.8,0-5.6,0-8.4,0l-8.3-0.1l-0.2,0c-41.8-0.4-85-0.7-125.3-9.7
		l-5.2-1.2l-0.4-0.1c-18.7-4.2-38.1-8.5-55.6-14.6C1310.4,2025.6,1305.1,2022,1299.5,2018L1299.5,2018z"/>
               </g>
               <g>
                    <g>
                         <g>
                              <path class="stT0" d="M683.9,1396.4c-24.1,0-53.2-13.7-63.8-44.3c-4.7-13.6-6.4-30.2-4.9-49.3c6.1-80.7,12.6-162.7,18.9-242l2-25
				c2.6-32.4,5.2-64.7,7.7-97.1c1-13.2,1.6-25.3,1.6-37c0.1-38.1,0.1-76.3,0.1-110.1l-0.7,3.3c-2.6,11.5-5.1,23.2-7.6,34.7l-1.3,5.9
				c-5.9,26.8-11.9,54.4-18.3,81.6c-4.1,17.7-15.4,33.3-31,42.7c-9.8,6-20.7,9.1-31.5,9.1c-4.6,0-9.1-0.6-13.5-1.7
				c-17.9-4.6-32.6-15.2-41.4-30c-8.7-14.6-11.2-32.5-7.2-50.5c7.6-34.1,15.3-68.9,22.8-102.5l2.2-9.8c6.7-30.4,13.7-61.7,20.6-92.6
				c7.3-32.5,13.5-59.4,19.7-84.4c5.7-22.9,21.2-39.7,43.9-47.2c53.6-17.8,102.3-28.7,149.1-33.5c2.2-0.2,4.4-0.3,6.5-0.3
				c10.5,0,19.3,2.8,26.1,8.4c7.5-5.2,16.9-7.8,27.3-7.8c1.6,0,3.2,0.1,4.8,0.2c64,4.9,124.1,24.8,182.2,43.9l7.8,2.6
				c43.6,14.4,90.1,29.8,142.1,47c15.1,5,37.9,12.6,49.9,36.3c11.9,23.4,9,51-7.4,70.2c-12.1,14.1-29.7,21.9-49.6,21.9
				c-8.7,0-17.5-1.5-26.2-4.4c-67.1-22.4-136.7-45.5-194.1-64.5c0,21.4-0.2,43.1-0.3,61c-0.4,58.7-0.8,119.5,0.9,179.1
				c1.2,42.9,5.1,86.6,8.9,128.8l0.1,1.5c1.5,17.2,3.1,34.9,4.5,52.5c3.8,48.3,7.7,96.1,12,149.1l1.7,21.1
				c1.2,15.3,2.5,30.6,3.7,45.8c1.6,20.2,0.6,49.5-26.9,71.2c-13.1,10.3-28.6,15.8-44.9,15.8c-8.7,0-17.2-1.6-25.4-4.7
				c-22.6-8.6-38.6-27.9-42.9-51.7c-4.6-25.8-6.6-51.8-8.5-77l-0.2-2.4c-0.4-5.8-0.9-11.7-1.4-17.5c-7.1-85.7-13.5-164.3-19.3-239.7
				c-8.1,103.3-16.8,212.9-25.7,323.5c-1.5,18.6-8.3,34.8-19.7,46.9c-11.4,12.1-27.1,19.7-45.5,22.2
				C689.4,1396.3,686.7,1396.4,683.9,1396.4z M656.1,615.8c0.9,0,1.8,0.2,2.7,0.6l24.1,10.2c2.4,1,4,3.3,4.3,5.9l0.7,9.1
				c0.1,1.3,0.2,2.7,0.4,4l0.1,1c0.4,4.2,0.9,8.9,0.9,14c0.1,72.1,0.1,157.2,0,241.3c0,12.7-0.6,25.9-1.7,40.3
				c-3.1,38.8-6.2,77.6-9.3,116.4c-6.5,81.1-13.2,164.9-19.4,247.5c-1,13.1-0.1,23.8,2.6,31.6c3.8,10.8,15.5,14.7,22.4,14.7
				c0.8,0,1.5,0,2.1-0.1c17.1-2.3,25.7-11.5,27.1-29c9.4-115.8,18.3-229.2,26.7-335.5l0.1-0.8c1-13.1,1.8-23.4,10.7-31.7
				c9-8.5,19.8-8.5,32.4-8.6c0.3,0,0.6,0,0.9,0c3.6-1,7.1-1.4,10.5-1.4c6.2,0,11.8,1.7,16.7,5c12.5,8.4,13.6,23.4,13.9,28.3
				c6.3,81.8,13.1,167.2,20.9,260.8c0.6,6.6,1.1,13.3,1.6,20.1c1.8,24.1,3.7,49,7.9,72.7c1.5,8.6,7.1,15.2,15.2,18.3
				c3.1,1.2,6.4,1.8,9.7,1.8c6.3,0,12.4-2.2,17.6-6.3c7.3-5.8,11.9-13.6,10.3-33.2c-1.2-15.1-2.4-30.2-3.7-45.3L903,1246
				c-4.3-53.2-8.2-101.1-12-149.2c-1.4-17.2-2.9-34.7-4.4-51.7l-0.2-1.8c-3.9-43-7.8-87.4-9.1-131.5c-1.7-60.4-1.3-121.5-0.9-180.6
				l0-2c0.1-23.7,0.3-48.2,0.3-72.2c0-9.1,0-23,11.6-31.4c4.5-3.3,9.8-4.9,15.6-4.9c6.5,0,12.7,2,18.1,3.8l3.2,1.1
				c62.8,20.8,133.9,44.4,203.4,67.6c4.3,1.4,8.5,2.2,12.4,2.2c4.8,0,11.4-1.1,16-6.5c4.9-5.8,5.5-14.1,1.6-21.8
				c-3.3-6.6-10-9.6-24.6-14.5c-51.5-17.1-97.9-32.5-142-47l-7.9-2.6c-55.6-18.4-113.1-37.3-171.8-41.8c-0.5,0-1-0.1-1.4-0.1
				c-0.5,0-1,0-1.4,0.1c0,0.5,0,1.1,0.1,1.8c3.2,25.2,6.7,51,10,75.8l0.3,2.4c4.1,30.8,8.3,62.7,12.3,94.3c0.2,1.8,0.5,3.7,0.8,5.6
				l0.1,0.4c1.7,11.9,3.9,26.7-1.1,40.8c-4.9,14-12.3,26.1-20.1,38.9c-2.9,4.7-6.5,10.6-9.9,16.6l-13.2,23.5
				c-1.2,2.2-3.6,3.6-6.1,3.6c0,0,0,0,0,0c-2.5,0-4.9-1.4-6.1-3.6l-20.4-37.1c-7.4-13.5-15.8-28.8-23.6-43.4
				c-4.2-7.8-3.1-15.4-2.6-19.1l0.1-0.6c1-7.3,2-14.6,3-21.9l0-0.3c1.4-9.8,2.6-18.6,3.8-27.4c6.5-49.9,12.3-94.6,17.7-136.7
				l0.3-2.1c0.4-3.4,0.9-7,0.7-8.9c-0.2-1.2-0.3-2.3-0.5-3.1c-43.4,4.5-89,14.7-139.3,31.4c-8.4,2.8-13,7.7-15,16
				c-6.1,24.6-12.3,51.2-19.5,83.5c-7.4,32.9-14.9,66.9-22,98.9l-2.4,10.9c-6.9,31.2-14,63.4-21.1,95.1
				c-3.2,14.4,2.8,24.7,16.5,28.2c0.8,0.2,1.6,0.3,2.5,0.3c2.8,0,5.9-1,8.7-2.7c5.5-3.3,9.5-8.9,11-15.1c6.3-26.9,12.3-54.4,18.1-81
				l0.1-0.3c2.9-13.2,5.9-26.9,8.9-40.4l3.3-14.7c8.4-37.9,18-81,27.6-122.6c1.8-7.8,6.2-13.3,9.1-16.9c0.3-0.4,1.2-1.5,1.6-2.1
				l6.9-10.2C651.7,616.9,653.9,615.8,656.1,615.8z M774.3,765c3.1,5.7,6.2,11.5,9.6,17.7c2.7-4.9,5.1-9.9,6.9-14.9
				c1.4-3.9,0.1-12.8-1-19.9c-0.3-2.3-0.7-4.6-0.9-6.8c-1.8-14.3-3.7-28.7-5.5-43c-0.8,6.3-1.6,12.6-2.5,19.1
				c-1,7.9-2.2,16-3.2,23.8L777,745C776.1,751.7,775.2,758.3,774.3,765z"/>
                              <path class="stT0" d="M757.7,523.6c12.7,0,20.6,4.7,25.7,10.8c6.9-6.7,16.6-10.2,27.8-10.2c1.4,0,2.8,0.1,4.2,0.2
				c63.2,4.8,122.9,24.5,180.6,43.6l7.8,2.6c44.2,14.6,90.7,30,142,47c14.8,4.9,35.1,11.7,45.9,32.9c10.6,20.9,8.1,45.4-6.5,62.5
				c-10.8,12.7-26.8,19.5-44.3,19.5c-7.8,0-15.9-1.3-24-4c-69.5-23.2-140.4-46.7-203.3-67.5c0,23.6-0.2,47.5-0.3,70.7
				c-0.4,58.8-0.8,119.7,0.9,179.3c1.2,43,5.1,86.8,8.9,129.2c1.6,17.7,3.2,35.9,4.6,53.9c3.9,49.7,8,100.2,12,149.1
				c1.8,22.3,3.6,44.6,5.4,66.9c1.5,18.6,0.6,45.5-24.2,65.2c-11.9,9.4-26.1,14.3-40.5,14.3c-7.7,0-15.4-1.4-22.9-4.2
				c-20.3-7.7-34.7-25.1-38.5-46.4c-4.5-25.4-6.5-51.3-8.4-76.3c-0.5-6.5-1-13.3-1.6-19.9c-7.8-93.2-14.6-177.9-20.8-258.9
				c-1.2,0.1-2.2,0.2-3.2,0.2c-0.4,0-0.8,0-1.2,0c-0.2,0-0.5,0-0.7,0c-2,0-4,0-5.9,0.1c-0.2,1.9-0.3,4.1-0.5,6.1l-0.1,0.8
				c-8.4,106.9-17.4,220.9-26.7,335.6c-2.8,34.7-24.9,58.1-59.1,62.6c-2.3,0.3-4.7,0.4-7.2,0.4c-22.2,0-47.9-12.9-57.2-39.6
				c-4.4-12.7-5.9-28.3-4.6-46.5c6.2-82.6,12.9-166.5,19.4-247.6c3.1-38.8,6.2-77.6,9.2-116.4c1.1-13.4,1.6-25.7,1.6-37.5
				c0.1-59,0.1-118.4,0.1-173.6c-3.8,17-7.6,33.9-11.3,50.5l-3.3,14.7c-3,13.5-6,27.3-8.9,40.6c-5.8,26.7-11.9,54.4-18.2,81.5
				c-3.7,15.9-13.8,29.8-27.8,38.3c-8.8,5.4-18.4,8.1-27.8,8.1c-4,0-7.9-0.5-11.8-1.5c-16.1-4.1-29.2-13.6-37.1-26.8
				c-7.8-13-10-29.1-6.4-45.3c7.9-35.3,15.8-71.2,23.5-105.8c7.2-32.4,14.6-66,22-99c7.3-32.6,13.5-59.4,19.7-84.3
				c5.1-20.5,19-35.5,39.3-42.2c53.1-17.6,101.3-28.4,147.6-33.2C754,523.7,755.9,523.6,757.7,523.6 M555,931.9
				c4,0,8.3-1.3,12.4-3.7c7.1-4.3,12.3-11.4,14.2-19.5c6.3-26.9,12.3-54.5,18.2-81.1c2.9-13.3,5.9-27.1,9-40.7l3.3-14.7
				c8.9-40.2,18.2-81.7,27.6-122.5c1.4-6.2,5.1-10.8,7.8-14.1c0.8-1,1.5-1.9,2-2.6l6.9-10.3l24.2,10.2l0.7,9.1
				c0.1,1.7,0.3,3.4,0.5,5.2c0.4,4.2,0.9,8.6,0.9,13.3c0.1,72.2,0.1,157.2,0,241.3c0,12.6-0.6,25.6-1.7,39.8
				c-3.1,38.8-6.1,77.6-9.2,116.4c-6.5,81.1-13.2,165-19.4,247.5c-1.1,14.1-0.1,25.7,3,34.4c4.8,13.8,19.1,19.4,29,19.4
				c1.1,0,2.1-0.1,3-0.2c20.3-2.7,31.5-14.6,33.1-35.3c9.3-114.6,18.3-228.6,26.7-335.5l0.1-0.8c1-12.5,1.6-20.7,8.5-27.2
				c7-6.6,15.6-6.6,27.6-6.7c0.4,0,0.8,0,1.2,0c0.2,0,0.3,0,0.5,0c3.4-1,6.6-1.5,9.6-1.5c4.8,0,9.1,1.3,12.8,3.8
				c9.6,6.5,10.5,18.5,10.9,23.1c6.3,81.5,13.1,166.9,21,260.9c0.6,6.7,1.1,13.5,1.6,20.1c1.9,24.3,3.8,49.4,8,73.4
				c2,11.1,9.1,19.7,19.6,23.6c4,1.5,8.1,2.2,12.2,2.2c7.8,0,15.5-2.7,22-7.8c10.7-8.5,14.5-19.9,12.9-39.2
				c-1.8-22.3-3.6-44.6-5.4-66.9c-4-48.9-8-99.4-12-149.1c-1.4-17.9-3-36-4.6-53.6c-3.8-42.9-7.8-87.2-9-131.1
				c-1.7-60.2-1.3-121.2-0.9-180.3c0.1-24.3,0.3-49.5,0.3-74.2c0-8.6,0-19.4,8.7-25.7c3.7-2.7,7.6-3.6,11.5-3.6
				c5.4,0,10.9,1.8,15.9,3.5c63.7,21.1,135.9,45,206.6,68.6c5.1,1.7,10,2.5,14.6,2.5c8.8,0,16.3-3.1,21.4-9
				c6.8-7.9,7.7-19.2,2.5-29.5c-4.8-9.5-14.4-13.3-28.6-18c-51.4-17.1-97.8-32.5-142-47l-7.8-2.6c-56-18.5-113.9-37.6-173.5-42.2
				c-0.7-0.1-1.3-0.1-1.9-0.1c-3.3,0-5.9,0.7-7,1.9c-1.2,1.3-1.7,4.2-1.2,7.9c3.4,26.1,6.9,52.6,10.3,78.2
				c4.1,30.9,8.4,62.8,12.3,94.3c0.2,2,0.5,4.1,0.8,6.1c1.7,11.7,3.6,25.1-0.7,37.5c-4.7,13.3-11.9,25.1-19.5,37.6
				c-3.3,5.4-6.6,10.9-10,16.8L783,857.9l-13-23.7c-2.5-4.6-5-9.1-7.4-13.4c-8.3-15.1-16.1-29.3-23.6-43.3
				c-3.1-5.8-2.3-11.7-1.8-14.8l0.1-0.6c1-7.4,2-14.8,3.1-22.3c1.3-9,2.6-18.4,3.8-27.5c6.5-49.7,12.3-94.4,17.7-136.7l0.2-1.9
				c0.5-4.1,1.1-8,0.7-10.9c-0.8-6.2-1.9-8.5-2.3-9.1c-0.4-0.1-1.1-0.2-2.5-0.2c-0.8,0-1.7,0-2.9,0.2c-44,4.5-90.2,14.9-141.2,31.8
				c-10.6,3.5-17,10.4-19.6,20.9c-6.1,24.7-12.3,51.3-19.5,83.7c-7.4,33-14.8,66.5-22,98.9c-7.7,34.7-15.6,70.6-23.5,105.9
				c-4,18.1,4.2,32.1,21.6,36.5C552.1,931.8,553.5,931.9,555,931.9 M783.7,796.9c5.7-9.5,10.6-18,13.7-26.8
				c1.9-5.5,0.6-14.5-0.6-23.3c-0.3-2.2-0.7-4.5-0.9-6.7c-4-31.4-8.2-63.3-12.3-94.1c-0.1-0.6-0.2-1.2-0.2-1.8
				c-3,22.9-6.1,46.8-9.4,71.9c-1.2,9.3-2.5,18.7-3.8,27.8c-1,7.4-2.1,14.7-3,22.1l0,0.2C772.4,776.3,778,786.4,783.7,796.9
				 M757.7,509.6c-2.3,0-4.7,0.1-7.2,0.4c-47.3,4.8-96.5,15.9-150.6,33.8c-12.4,4.1-22.9,10.7-31.2,19.7c-8.4,9-14.2,19.9-17.3,32.5
				c-6.2,25.1-12.5,52-19.8,84.6c-6.9,30.9-13.9,62.4-20.6,92.8l-1.4,6.2L509,783c-7.4,33.6-15.1,68.3-22.7,102.3
				c-4.4,19.7-1.6,39.5,8,55.6c9.8,16.4,26,28.2,45.6,33.2c5,1.3,10.1,1.9,15.2,1.9c12,0,24.2-3.5,35.1-10.1
				c17.2-10.4,29.6-27.6,34.2-47.1c4.9-20.8,9.5-41.8,14.1-62.5c0,15,0,30.3-0.1,45.5c0,11.5-0.5,23.4-1.6,36.4
				c-2.5,32.4-5.2,65.4-7.7,97.2l-1.5,19.1l-0.5,5.9c-6.3,79.3-12.9,161.2-18.9,241.8c-1.5,20.1,0.3,37.7,5.3,52.2
				c5.4,15.4,15.5,28.2,29.5,37c12.2,7.7,26.7,12,40.9,12c3,0,6.1-0.2,9-0.6c0,0,0,0,0.1,0c20-2.7,37.2-11.1,49.6-24.3
				c12.5-13.2,19.9-30.9,21.5-51.1c6.4-79.5,12.7-158.4,18.8-234.8c3.9,48.3,7.9,98.4,12.3,151.1c0.5,5.8,0.9,11.7,1.4,17.5l0.2,2.3
				c1.9,25.4,3.9,51.6,8.6,77.7c4.7,26.3,22.4,47.6,47.3,57.1c9,3.4,18.4,5.1,27.9,5.1c17.9,0,34.9-6,49.2-17.3
				c13.4-10.6,22.4-24.1,26.8-40.3c3.9-14.4,3.4-28,2.7-37c-1.2-15.1-2.4-30.2-3.6-45.3l-1.8-21.7c-4.1-50.9-8.1-100.6-12-149.1
				c-1.4-17.5-3-35.3-4.5-52.5l-0.1-1.6c-3.8-42.1-7.7-85.7-8.9-128.4c-1.7-59.5-1.3-120.2-0.9-178.8c0.1-15.2,0.2-33.2,0.3-51.4
				c55.6,18.4,121.4,40.2,184.9,61.4c9.5,3.2,19,4.8,28.4,4.8c22,0,41.6-8.7,55-24.4c18.2-21.4,21.5-52,8.3-77.9
				c-13.4-26.3-37.8-34.4-54-39.8c-51.9-17.3-98.4-32.6-142.1-47.1l-7.8-2.6c-58.5-19.3-119-39.3-183.9-44.3
				c-1.8-0.1-3.6-0.2-5.3-0.2c-9.9,0-19.1,2.3-27,6.5C777.8,512.6,769.2,509.6,757.7,509.6L757.7,509.6z M555,917.9
				c-0.4,0-0.6,0-0.7-0.1c-10-2.6-13.8-9.3-11.4-19.9c7.1-31.8,14.3-64.2,21.2-95.6l2.3-10.4c7.1-32,14.6-66,22-98.9
				c7.2-32.3,13.4-58.8,19.5-83.4c1.5-5.9,4.4-9,10.4-11c47-15.6,89.7-25.5,130.4-30.3c-0.1,1.1-0.3,2.3-0.4,3.3l0,0.2l-0.2,1.9
				c0,0,0,0,0,0.1c-5.4,42.1-11.2,86.8-17.7,136.7c-1.1,8.8-2.4,17.6-3.7,27.4c-1,7.3-2.1,14.8-3.1,22.3l-0.1,0.6
				c-0.6,4-1.9,13.5,3.3,23.4c7.3,13.6,14.7,27.2,23.7,43.5l3.2,5.7c1.4,2.5,2.8,5.1,4.2,7.7l13,23.7c2.4,4.5,7.1,7.2,12.2,7.3
				c0,0,0.1,0,0.1,0c5,0,9.7-2.7,12.2-7.1l13.2-23.5c3.3-5.9,6.9-11.7,9.7-16.4c8-13.2,15.6-25.6,20.7-40.2
				c5.5-15.7,3.2-31.5,1.4-44.2l-0.1-0.4c-0.3-1.9-0.5-3.7-0.8-5.5c-4-31.7-8.2-63.6-12.3-94.4l-0.4-2.6c-3-22.6-6.1-46-9.1-69
				c55.9,5.2,111.1,23.4,164.5,41l7.9,2.6c44.1,14.5,90.5,29.9,141.9,47c12.4,4.1,18.3,6.7,20.5,11c2.6,5.2,2.4,10.4-0.7,14.1
				c-2.9,3.3-7.4,4.1-10.7,4.1c-3.1,0-6.6-0.6-10.2-1.8c-69.5-23.2-140.6-46.7-203.3-67.5l-3.4-1.1c-5.6-1.9-12.7-4.2-20.3-4.2
				c-7.3,0-13.9,2.1-19.7,6.3c-14.5,10.5-14.5,27.1-14.5,37c0,23.9-0.2,48.3-0.3,71.8l0,2.3c-0.4,59.2-0.8,120.3,0.9,180.8
				c1.2,44.3,5.2,88.9,9.1,131.9l0.2,1.7c1.5,17,3.1,34.6,4.4,51.7c3.8,48.1,7.7,96,12,149.2l1.8,22c1.2,15,2.4,29.9,3.6,44.9
				c1.5,18.3-2.8,23.3-7.7,27.1c-3.9,3.1-8.5,4.8-13.3,4.8c-2.5,0-4.9-0.4-7.2-1.3c-5.8-2.2-9.7-6.8-10.8-13c-4.1-23.3-6-48-7.9-72
				c-0.5-6.8-1-13.5-1.6-20.2c-7.8-93.6-14.7-178.9-20.9-260.8c-0.4-5.7-1.7-23.2-17-33.6c-6-4.1-13-6.2-20.6-6.2
				c-3.6,0-7.4,0.5-11.3,1.4c0,0,0,0,0,0c-13.5,0.1-26.2,0.1-37.2,10.5c-10.8,10.2-11.8,22.9-12.9,36.3l-0.1,0.8
				c-8.4,106.4-17.3,220-26.7,335.5c-1.2,14.2-7.2,20.7-21,22.6c-0.2,0-0.6,0.1-1.2,0.1c-4.8,0-13.3-2.7-15.8-10
				c-2.4-6.8-3.1-16.8-2.2-28.8c6.2-82.5,12.9-166.4,19.4-247.4c3.8-47,6.6-82.9,9.3-116.4c1.2-14.6,1.7-28,1.7-40.9
				c0.2-84.1,0.1-169.2,0-241.3c0-5.4-0.5-10.4-1-14.7l-0.1-1c-0.1-1.3-0.3-2.6-0.4-3.9l-0.7-9.1c-0.4-5.2-3.7-9.7-8.5-11.8
				l-24.1-10.2c-1.8-0.7-3.6-1.1-5.4-1.1c-4.6,0-9,2.2-11.6,6.2l-6.9,10.2c-0.1,0.1-0.4,0.5-1.2,1.6c0,0,0,0,0,0
				c-3.1,3.9-8.3,10.3-10.5,19.7c-9.7,41.7-19.2,84.7-27.6,122.7l-3.3,14.7c-3,13.5-6,27.2-8.9,40.5l-0.1,0.3
				c-5.8,26.6-11.8,54-18.1,80.9c-1,4.4-3.9,8.3-7.8,10.7C557.6,917.8,555.7,917.9,555,917.9L555,917.9z M781.5,763.7
				c0.5-3.8,1.1-7.9,1.7-12.4c0.7,5,1.6,12,0.9,14.3c-0.2,0.6-0.4,1.2-0.7,1.8C782.8,766.1,782.2,764.9,781.5,763.7L781.5,763.7z"/>
                         </g>
                         <g>
                              <path class="stT0" d="M783.4,552.5c-20.2,0-40.4-6.5-58.4-18.8c-54.8-37.5-81.1-109.5-61.2-167.7C681,315.7,730,280.5,783,280.5
				c6.2,0,12.5,0.5,18.6,1.5c30.6,4.8,58.1,20.3,77.3,43.7c19.6,23.8,30,54.1,30.1,87.7v0.5c0,0.1,0,0.3,0,0.4l0,0.5
				c-3.1,49.2-27.8,91.9-69.6,120.2C822.3,546.5,802.9,552.5,783.4,552.5z M782.8,324.5c-34.4,0-66.2,23-77.4,55.8
				c-13.5,39.4,6,90.9,44.4,117.1c10.8,7.4,22.1,11.1,33.6,11.1c10.9,0,21.4-3.4,31.3-10.1c38.5-26.1,48.6-61,50.3-85.8
				c-0.4-45.7-28.6-80.8-70.3-87.3C790.8,324.8,786.8,324.5,782.8,324.5z"/>
                              <path class="stT0" d="M783,287.5c5.8,0,11.7,0.4,17.5,1.4c28.9,4.6,54.9,19.2,73,41.2c18.6,22.5,28.4,51.3,28.5,83.3v0.5l0,0.5
				c-2.9,47-26.6,87.7-66.6,114.8c-16.2,11-34,16.4-52,16.4c-18.6,0-37.3-5.9-54.5-17.6c-52.3-35.8-77.5-104.4-58.6-159.6
				C686.9,319.9,733.5,287.5,783,287.5 M783.4,515.5c12.1,0,24.1-3.7,35.2-11.3c40.9-27.7,51.7-64.9,53.4-91.3
				c-0.3-49.4-30.9-87.3-76.2-94.4c-4.3-0.7-8.7-1-13-1c-36.9,0-71.7,24.3-84.1,60.6c-14.4,42.2,6.2,97.2,47.1,125.2
				C757.9,511.4,770.7,515.5,783.4,515.5 M783,273.5v14V273.5c-27,0-54.2,8.8-76.8,24.9c-23.1,16.4-40,39.1-49.1,65.4
				c-10.2,29.7-9.2,64,2.7,96.5c11.8,32.2,33.5,60.3,61.2,79.2c19.2,13.1,40.7,20,62.4,20c20.9,0,41.6-6.5,59.8-18.8
				c43.7-29.6,69.5-74.1,72.7-125.5l0-0.5c0-0.3,0-0.6,0-0.9v-0.5c-0.1-35.3-11.1-67.1-31.7-92.2c-20.3-24.6-49.3-41-81.6-46.1
				C796.2,274,789.6,273.5,783,273.5L783,273.5z M783.4,501.5c-10,0-20-3.3-29.6-9.9c-35.3-24.2-54.1-73.1-41.8-109.1
				c10.3-30.1,39.4-51.1,70.8-51.1c3.6,0,7.3,0.3,10.8,0.8c38.1,6,63.9,38.2,64.4,80.2c-1.6,23.2-11.2,55.8-47.3,80.2
				C802.1,498.5,792.9,501.5,783.4,501.5L783.4,501.5z"/>
                         </g>
                    </g>
               </g>
               <g>
                    <g>
                         <g>
                              <path class="stT0" d="M1229.3,1136.8c-47.2,0-94.4,0-141.6,0l-102.5,0c-0.9,0-2.1,0-3.4,0l-5.1,0c-3.7,0-6.7-2.9-6.9-6.6l-0.3-5.1
				c-0.1-1.1-0.1-2.2-0.2-3.1l0-0.2c-0.1-1.2-0.1-2.4-0.2-3.6l-0.1-2.2c-0.3-6.6-0.7-14.1-1.4-21.5l-0.5-5.9
				c-0.2-1.9,0.5-3.9,1.8-5.3c1.3-1.4,3.2-2.3,5.1-2.3l7.7-0.1c1.6,0,3.2,0,4.8,0c45.2,0,90.4,0,135.6,0l11.1,0c48.5,0,97,0,145.5,0
				c102,0,183.8,0.1,257.5,0.2h0.3c41.4,0,72.5-14,95.1-42.8c15.5-19.7,23.3-44.4,23.3-73.4c0.2-229.6,0.2-421.7,0-612.9
				c-0.1-68.1-48-115.8-116.5-115.9c-69.9-0.1-144.9-0.1-236-0.1c-32.7,0-65.4,0-98.1,0l-50,0c-49.4,0-98.8,0-148.2,0
				c0,0-240.1,0-282.9,0c-103.2,0-184,0-262.1-0.1h-0.1c-40.6,0-72.7,14.5-95.2,43.1c-15.4,19.6-23.2,43.8-23.2,72.1
				c-0.2,195.8-0.4,404.7,0,615.5c0.1,66.4,46.9,113.4,113.8,114.2c15.1,0.2,31.6,0.3,52.1,0.3c11.6,0,23.3,0,34.9,0h0.7
				c11.1,0,22.3-0.1,33.5-0.1c3.1,0,6.2,0,9.4,0l6,0c2,0,3.8,0.8,5.1,2.3c1.3,1.4,2,3.4,1.8,5.3l-0.5,5.9
				c-0.5,6.6-1.2,13.2-1.9,19.6l-0.1,0.9c-0.4,3.5-0.7,7-1.1,10.5l-0.5,5.1c-0.3,3.6-3.4,6.3-7,6.3c0,0-5.2-0.1-5.2-0.1
				c-14-0.2-28.2-0.2-42-0.3l-2.3,0c-39-0.1-79.2-0.1-118.3-4.2c-43.5-4.5-80-29.2-108.4-73.4c-17.1-26.6-25.4-57.4-25.3-94.1
				c0.1-91,0-183.4,0-272.9V691c0-110.7,0-225.1,0.1-339.2c0-40.8,10.9-76,32.1-104.5c21.3-28.6,51.9-48.9,90.9-60.4
				c14.4-4.2,31.7-6.4,51.4-6.4c200.2-0.2,408.3-0.3,654.8-0.3c139.6,0,279.1,0,418.7,0.1c60.4,0,106.3,21.4,140.4,65.3
				c19.2,24.7,30.4,53.6,33.5,86.1c0.8,8.1,0.8,16.6,0.8,23.8c0,63.1,0,126.1,0,189.2v4c0,135.2,0,275.1,0.2,413.2
				c0.1,48.9-14.1,88.2-43.4,120.2c-28.5,31.2-61.4,48.1-100.4,51.9c-19.1,1.8-39,2.7-61.1,2.8
				C1428,1136.8,1342.9,1136.8,1229.3,1136.8z"/>
                              <path class="stT0" d="M1116.4,187.2c139.6,0,279.1,0,418.7,0.1c58.1,0,102.2,20.5,134.9,62.6c18.3,23.6,29.1,51.4,32,82.5
				c0.7,7.9,0.8,16.1,0.8,23.1c0,63.1,0,126.1,0,189.2v3.8c0,135.3,0,275.3,0.2,413.5c0.1,47.1-13.5,84.8-41.6,115.5
				c-27.3,29.8-58.7,46-95.9,49.6c-18.8,1.8-38.6,2.7-60.4,2.7c-79.6,0.1-164.7,0.1-275.8,0.1c-47.2,0-94.4,0-141.6,0l-102.5,0
				c-0.9,0-2.1,0-3.3,0l-5.1,0l-0.3-5.1c-0.1-1.2-0.1-2.4-0.2-3.3c-0.1-2-0.2-3.9-0.3-5.8c-0.3-7.1-0.7-14.4-1.4-21.7l-0.5-5.9
				l7.7-0.1c1.6,0,3.1,0,4.7,0c46.9,0,93.9,0,140.9,0h5.8c48.5,0,97,0,145.5,0c101.3,0,183.1,0.1,257.5,0.2h0.3
				c43.7,0,76.6-14.9,100.6-45.5c16.5-20.9,24.8-47.1,24.8-77.7c0.2-228,0.2-419.5,0-612.9c-0.1-72.2-50.9-122.8-123.4-122.9
				c-68.4-0.1-143.5-0.2-236.1-0.2c-49.4,0-98.8,0-148.2,0c-49.4,0-98.8,0-148.2,0c0,0-240.1,0-282.9,0c-106.1,0-187,0-262.1-0.1
				h-0.1c-42.9,0-76.8,15.4-100.7,45.8c-16.4,20.8-24.7,46.5-24.7,76.4c-0.2,195.9-0.4,404.8,0,615.5
				c0.1,70.5,49.8,120.3,120.7,121.2c15.3,0.2,31.9,0.3,52.2,0.3c11.6,0,23.3,0,34.9-0.1h0.6c11.2,0,22.3-0.1,33.4-0.1
				c3.1,0,6.3,0,9.4,0l6,0l-0.5,5.9c-0.5,6.4-1.2,12.7-1.9,19.4c-0.4,3.8-0.8,7.6-1.2,11.4l-0.5,5.1l-5.1-0.1
				c-14.9-0.2-29.9-0.2-44.4-0.3c-38.9-0.1-79-0.1-117.6-4.1c-41.3-4.2-76-27.9-103.2-70.2c-16.3-25.5-24.3-55-24.2-90.3
				c0.1-91,0.1-183.6,0-273.1V691c0-111,0-225.8,0.1-339.2c0-39.3,10.4-73,30.7-100.3c20.4-27.4,49.7-46.8,87.2-57.8
				c13.7-4,30.4-6.1,49.4-6.1C657.2,187.4,865.3,187.2,1116.4,187.2 M1116.4,173.2c-246.5,0-454.6,0.1-654.9,0.3
				c-20.4,0-38.3,2.3-53.4,6.7c-40.5,11.9-72.3,33.1-94.5,62.9c-22.2,29.8-33.5,66.4-33.5,108.7c-0.1,113.3-0.1,228-0.1,339v0.2v0.6
				c0,89.3,0,181.7,0,273.1c0,38.1,8.6,70.1,26.5,97.9c14.5,22.7,31.4,40.6,50.2,53.4c19.1,13,40.5,20.8,63.4,23.2
				c39.5,4,79.9,4.1,119,4.2l2.2,0c13.8,0,28,0.1,42,0.3l5.1,0.1c0.1,0,0.1,0,0.2,0c7.2,0,13.2-5.5,13.9-12.6l0.5-5.1
				c0.3-3.5,0.7-7,1.1-10.4l0.1-0.9c0.7-6.4,1.4-13.1,1.9-19.8l0.5-5.9c0.3-3.9-1-7.7-3.7-10.6c-2.6-2.9-6.4-4.5-10.3-4.5l-6,0
				c-3.1,0-6.3,0-9.4,0c-11.7,0-23.5,0-33.5,0.1h-0.6c-13,0-24.4,0.1-34.9,0.1c-20.5,0-37-0.1-52-0.3
				c-62.8-0.7-106.8-44.8-106.9-107.2c-0.4-210.9-0.2-419.7,0-615.5c0-26.7,7.3-49.5,21.7-67.8c21.1-26.9,51.3-40.5,89.7-40.5h0.1
				c77.9,0.1,158.8,0.1,262.1,0.1c42.9,0,282.9,0,282.9,0c49.4,0,98.8,0,148.2,0l49.7,0c32.8,0,65.6,0,98.4,0c91,0,166,0,236,0.2
				c64.4,0.1,109.4,44.9,109.5,108.9c0.3,191.2,0.2,383.3,0,612.9c0,27.4-7.4,50.6-21.8,69.1c-21.5,27.4-50,40.1-89.6,40.1h-0.3
				c-73.7-0.1-155.6-0.2-257.5-0.2c-48.5,0-97,0-145.5,0h-5.8c-47,0-93.9,0-140.9,0c-1.6,0-3.2,0-4.9,0l-7.7,0.1
				c-3.9,0-7.6,1.7-10.2,4.6s-3.9,6.8-3.5,10.6l0.5,5.9c0.7,7.3,1,14.6,1.3,21.2l0.1,2.2c0.1,1.2,0.1,2.4,0.2,3.6l0,0.2
				c0,0.9,0.1,2,0.2,3.2l0.3,5.1c0.4,7.3,6.5,13.1,13.9,13.2l5.1,0c1.3,0,2.5,0,3.4,0l102.5,0c47.2,0,94.4,0,141.6,0
				c113.5,0,198.6,0,275.8-0.1c22.2,0,42.4-0.9,61.7-2.8c40.8-3.9,75.1-21.6,104.9-54.1c30.5-33.3,45.3-74.2,45.3-124.9
				c-0.2-138.6-0.2-278.3-0.2-413.4v-3.8c0-62,0-126.1,0-189.2c0-7.4-0.1-16-0.9-24.4c-3.2-33.8-14.9-64-34.9-89.7
				c-17.6-22.7-38.9-39.9-63.1-51.2c-24-11.1-51.9-16.8-82.9-16.8C1366.2,173.3,1237.1,173.2,1116.4,173.2L1116.4,173.2z"/>
                         </g>
                         <g>
                              <path class="stT0" d="M1388.3,968.9l-5.8-0.3c-4.5-0.2-9-0.3-13.8-0.3c-4.4,0-9,0.1-14,0.3l-5.8,0.2c-0.1,0-0.2,0-0.3,0
				c-1.8,0-3.6-0.7-4.9-2c-1.4-1.3-2.1-3.2-2.1-5.1l0-5.8c0.2-19.5,0.2-39,0.1-57.8c0-8.5-0.1-16.8-0.1-25.2l0-70.2
				c0-61.9,0-123.9,0-185.8v-5.5c0-3.8,3.1-7,6.9-7l5.5,0c3.3,0,6.9,0,11,0c4.2,0,8.4,0,12.5,0l10.8,0c3.9,0,7,3.1,7,7v5.5
				c0,45.2,0,90.4,0,135.6v36.4c0,14.8,0,29.6,0,44.4v0.2c0,40.1-0.1,81.5,0.2,122.6l0.1,5.8c0,1.9-0.8,3.8-2.2,5.1
				c-1.3,1.3-3,1.9-4.8,1.9C1388.5,968.9,1388.4,968.9,1388.3,968.9z"/>
                              <path class="stT0" d="M1365,611.3c4.2,0,8.3,0,12.5,0l10.8,0v5.5c0,45.2,0,90.4,0,135.6v36.4c0,14.8,0,29.6,0,44.4v0.2
				c-0.1,40.1-0.1,81.7,0.2,122.6l0.1,5.8l-5.8-0.3c-4.5-0.2-9.2-0.3-14.2-0.3c-4.5,0-9.2,0.1-14.3,0.3l-5.8,0.2l0-5.8
				c0.2-19.4,0.2-39,0.1-57.9c0-8.4-0.1-16.8-0.1-25.2l0-70.2c0-61.9,0-123.9,0-185.8v-5.5l5.5,0
				C1357.4,611.4,1361,611.3,1365,611.3 M1365,597.3c-4.1,0-7.7,0-11,0l-5.5,0c-7.7,0.1-13.9,6.3-13.9,14v5.5c0,62,0,123.9,0,185.8
				l0,70.2c0,7.6,0,15.6,0.1,25.2c0,18.8,0.1,38.3-0.1,57.7l0,5.8c0,3.8,1.5,7.5,4.2,10.1c2.6,2.5,6.1,4,9.8,4c0.2,0,0.3,0,0.5,0
				l5.8-0.2c5-0.2,9.5-0.3,13.8-0.3c4.7,0,9.1,0.1,13.5,0.3l5.8,0.3c0.2,0,0.5,0,0.7,0c3.6,0,7.1-1.4,9.7-3.9
				c2.8-2.7,4.3-6.4,4.3-10.2l0-5.8c-0.3-41.1-0.3-82.4-0.2-122.5v-0.2l0-0.2c0-14.7,0-29.5,0-44.2v-36.4c0-50.7,0-95,0-135.6v-5.5
				c0-7.7-6.2-14-14-14l-10.8,0C1373.4,597.4,1369.2,597.3,1365,597.3L1365,597.3z"/>
                         </g>
                         <g>
                              <path class="stT0" d="M1483.1,968.9c-0.1,0-0.3,0-0.4,0l-5.8-0.4c-4.4-0.3-8.9-0.4-13.7-0.4c-4.4,0-9,0.1-14,0.3l-5.8,0.2
				c-0.1,0-0.2,0-0.3,0c-1.8,0-3.5-0.7-4.9-2c-1.4-1.3-2.2-3.2-2.1-5.1l0-6.8c0.2-23,0.1-46.1,0.1-68.4c0-10,0-19.9,0-29.7l0-100.5
				c0-67.4,0-134.8,0-202.2v-6.5c0-3.8,3.1-7,6.9-7l5.5-0.1c3.3,0,6.9,0,11,0c2.8,0,5.6,0,8.4,0l15,0c3.9,0,7,3.1,7,7v6.5
				c0,53.4,0,106.8,0,160.2v43c0,17.5,0,35,0,52.5v0.3c0,47.3-0.1,96.3,0.2,144.9l0,6.9c0,1.9-0.8,3.8-2.2,5.1
				C1486.6,968.2,1484.9,968.9,1483.1,968.9z"/>
                              <path class="stT0" d="M1459.5,547.6c4.2,0,8.3,0,12.5,0l10.8,0v6.5c0,53.4,0,106.8,0,160.2v43c0,17.5,0,35,0,52.5v0.3
				c-0.1,47.5-0.1,96.5,0.2,144.9l0.1,6.9l-5.8-0.3c-4.5-0.3-9.2-0.4-14.1-0.4c-4.5,0-9.2,0.1-14.3,0.3l-5.8,0.2l0-6.8
				c0.2-22.9,0.2-46,0.1-68.4c0-10-0.1-19.8-0.1-29.7l0-83c0-73.2,0-146.4,0-219.7v-6.5l5.5-0.1
				C1451.9,547.6,1455.4,547.6,1459.5,547.6 M1459.5,533.6c-4.6,0-8,0-11.1,0l-5.5,0c-7.7,0.1-13.9,6.3-13.9,14v6.5
				c0,72.6,0,145.1,0,217.7l0,1.9l0,83c0,8.9,0,18.4,0.1,29.8c0,22.3,0.1,45.3-0.1,68.3l0,6.8c0,3.8,1.5,7.5,4.3,10.2
				c2.6,2.5,6.1,3.9,9.7,3.9c0.2,0,0.4,0,0.6,0l5.8-0.2c5-0.2,9.5-0.3,13.7-0.3c4.6,0,9,0.1,13.3,0.4l5.8,0.3c0.3,0,0.6,0,0.8,0
				c3.6,0,7-1.4,9.6-3.8c2.8-2.7,4.4-6.4,4.4-10.3l-0.1-6.9c-0.3-48.6-0.3-97.5-0.2-144.8v-0.3c0-17.5,0-35,0-52.5v-43
				c0-59.9,0-112.3,0-160.2v-6.5c0-7.7-6.2-14-14-14l-10.8,0l-4.2,0C1465.1,533.6,1462.3,533.6,1459.5,533.6L1459.5,533.6z"/>
                         </g>
                         <g>
                              <path class="stT0" d="M1283.2,968.6c-2.8-0.1-5.7-0.1-9-0.1c-4,0-8,0-11,0.1l-2.4,0c-2.2,0-4.3,0-6.5,0.1c0,0-5.5,0-5.5,0
				c-1.8,0-3.6-0.7-4.9-2c-1.3-1.3-2.1-3.1-2.1-5v-5.5c0-27.5,0-55,0-82.5v-34.9c0-10.4,0-20.8,0-31.2l0-2.3
				c0-27.6,0.1-56.1-0.2-84.3l-0.1-5.8c0-1.9,0.8-3.8,2.2-5.1c1.3-1.3,3-1.9,4.8-1.9c0.1,0,0.2,0,0.3,0l5.8,0.3
				c4.2,0.2,8.7,0.3,13.2,0.3c4.8,0,9.7-0.1,15-0.4l5.8-0.3c0.1,0,0.2,0,0.3,0c1.8,0,3.5,0.7,4.9,2c1.4,1.3,2.2,3.2,2.1,5.1l0,5.8
				c-0.2,36.2-0.2,72.7-0.2,107.9l0,14.6c0,9.2,0,18.4,0,27.6c0,8.8,0,17.6-0.1,26.4c-0.1,19.1-0.1,38.8,0.1,58.4l0.1,5.7
				c0,1.9-0.7,3.7-2.1,5.1c-1.3,1.3-3.1,2-4.9,2C1289,968.8,1283.2,968.6,1283.2,968.6z"/>
                              <path class="stT0" d="M1289.2,715.1L1289.2,715.1 M1289.2,715.1l0,5.8c-0.2,36.1-0.2,72.6-0.2,108c0,14.1,0,28.2,0,42.2
				c0,8.8,0,17.6-0.1,26.3c-0.1,19.2-0.1,38.9,0.1,58.5l0.1,5.7l-5.7-0.2c-2.8-0.1-5.8-0.1-9.2-0.1c-3.7,0-7.5,0-11.1,0.1h-0.3
				c-2.8,0-5.7,0.1-8.5,0.1l-5.5,0v-5.5c0-27.5,0-55,0-82.5v-34.9c0-10.9,0-21.8,0-32.7v-0.8c0-27.6,0.1-56.2-0.2-84.3l-0.1-5.8
				l5.8,0.3c4.4,0.2,8.9,0.3,13.6,0.3c4.9,0,10-0.1,15.4-0.4L1289.2,715.1 M1248.6,701.1c-3.6,0-7.1,1.4-9.7,3.9
				c-2.8,2.7-4.3,6.4-4.3,10.2l0,5.8c0.3,28.3,0.2,56.7,0.2,84.2v0.8l0,0.1c0,10.9,0,21.7,0,32.6v34.9c0,30.8,0,57.8,0,82.5v5.5
				c0,3.7,1.5,7.3,4.1,9.9c2.6,2.6,6.2,4.1,9.9,4.1c0,0,0.1,0,0.1,0l5.5,0c2.2,0,4.4,0,6.6-0.1l1.9,0h0.2c0.1,0,0.1,0,0.2,0
				c3,0,7-0.1,10.9-0.1c3.2,0,6.1,0,8.8,0.1l5.7,0.2c0.1,0,0.3,0,0.4,0c3.7,0,7.2-1.4,9.8-4c2.7-2.7,4.2-6.3,4.2-10.1l-0.1-5.7
				c-0.2-19.6-0.2-39.3-0.1-58.3c0-8.8,0.1-17.6,0.1-26.4c0-9.4,0-18.7,0-28.1l0-14.2c0-35.3-0.1-71.7,0.2-107.9l0-5.5
				c0-0.1,0-0.2,0-0.4c0-7.7-6.2-14-13.9-14c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6,0l-5.7,0.3
				c-5.2,0.2-10,0.3-14.7,0.3c-4.4,0-8.8-0.1-12.9-0.3l-5.8-0.3C1249.1,701.1,1248.8,701.1,1248.6,701.1L1248.6,701.1z
				 M1289.2,729.1L1289.2,729.1L1289.2,729.1L1289.2,729.1z"/>
                         </g>
                         <g>
                              <path class="stT0" d="M1147.1,968.7c-1.8,0-3.6-0.7-4.9-2c-1.3-1.3-2.1-3.1-2.1-5l0-5.6c0.1-18.3,0.1-36.6,0-54.2v-1.2
				c0-6.8,0-13.5,0-20.3c0-7.9,0-15.8,0-23.7v-0.2c0-16.7,0.1-33.9-0.1-50.9l-0.1-5.7c0-1.9,0.7-3.7,2.1-5.1c1.3-1.3,3.1-2,4.9-2
				c0.1,0,5.9,0.2,5.9,0.2c4.6,0.1,9.3,0.2,14.4,0.2c4.4,0,9.1,0,14.4-0.1c0,0,5.7-0.1,5.7-0.1c1.8,0,3.6,0.7,4.9,2
				c1.3,1.3,2.1,3.1,2.1,5l0,5.6c-0.1,55.4-0.1,104.7,0,150.7l0,5.6c0,1.9-0.7,3.7-2.1,5c-1.3,1.3-3.1,2-4.9,2c0,0-5.7-0.1-5.7-0.1
				c-4.8-0.1-9.5-0.1-14.2-0.1c-4.9,0-9.8,0-14.6,0.1C1152.8,968.6,1147.1,968.7,1147.1,968.7z"/>
                              <path class="stT0" d="M1147,799.7l5.7,0.2c4.6,0.1,9.4,0.2,14.5,0.2c4.5,0,9.3,0,14.5-0.1l5.6-0.1l0,5.6
				c-0.1,54.2-0.1,103.5,0,150.7l0,5.6l-5.6-0.1c-4.9-0.1-9.6-0.1-14.3-0.1c-5,0-9.9,0-14.8,0.1l-5.6,0.1l0-5.6
				c0.1-18.2,0.1-36.5,0-54.2v-1.2c0-6.8,0-13.5,0-20.3c0-7.9,0-15.8,0-23.7c0-16.8,0.1-34.1-0.1-51.2L1147,799.7 M1187.4,799.8
				L1187.4,799.8 M1147,785.7c-3.7,0-7.2,1.4-9.8,4c-2.7,2.7-4.2,6.3-4.2,10.1l0.1,5.7c0.2,17.1,0.1,34.4,0.1,51
				c0,7.8,0,15.8,0,23.8c0,6.8,0,13.5,0,20.3v1.2c0,17.6,0.1,35.9,0,54.2l0,5.6c0,3.8,1.5,7.4,4.2,10c2.6,2.6,6.2,4,9.8,4
				c0.1,0,0.2,0,0.2,0l5.6-0.1c4.8-0.1,9.7-0.1,14.5-0.1c4.6,0,9.4,0,14.1,0.1l5.6,0.1c0.1,0,0.2,0,0.2,0c3.7,0,7.2-1.4,9.8-4
				c2.7-2.6,4.2-6.2,4.2-10l0-5.6c-0.1-45.9-0.1-95.2,0-150.6l0-5.3c0-0.1,0-0.2,0-0.3c0-7.7-6.3-14-14-14h0c0,0,0,0,0,0
				c0,0,0,0,0,0h0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0l-5.6,0.1c-5.3,0.1-9.9,0.1-14.3,0.1
				c-5,0-9.6-0.1-14.2-0.2l-5.7-0.2C1147.2,785.7,1147.1,785.7,1147,785.7L1147,785.7z"/>
                         </g>
                    </g>
               </g>
          </svg>
     )
}