import React from 'react'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import {
    bindMenu,
} from 'material-ui-popup-state/hooks'


export default function HoverMenuComponent({ popupState, menuPaperClassName, children, anchorOrigin = { vertical: "bottom", horizontal: "left" }, transformOrigin = { vertical: "top", horizontal: "left" } }) {
    return (
        <HoverMenu
            {...bindMenu(popupState)}
            PaperProps={{
                className: menuPaperClassName
            }}
            variant='menu'
            transitionDuration={0}
            getContentAnchorEl={null}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {children}
        </HoverMenu>
    )
}
