import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { contentMaxWidth, facebookPartner, googlePartner } from '../utitlities/Configurations'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import LinkComponent from './Gatsby/LinkComponent'
import LocationIcon from '@material-ui/icons/LocationOn'
import CallIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Email'
import SocialLinksRender from './General/SocialLinksRender'
import { useStaticQuery, graphql } from 'gatsby'
import clsx from 'clsx'
import { socialIconList } from '../utitlities/IconRenderMap'

const useStyles = makeStyles(theme => ({
    footer: {
        marginTop: '1rem',
        position: 'relative',
    },    
    footerInnerContainer: {
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "1rem 1rem 2rem",
        [theme.breakpoints.up('sm')]: {
            padding: "4rem 2rem"
        }
    },
    footerInnerContainer2: {
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "0px 1rem 2rem",
        [theme.breakpoints.up('sm')]: {
            padding: "0px 2rem 4rem"
        }
    },
    footerBg: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1
    },
    disclaimerContainer: {
        padding: "1rem 2rem",
        maxWidth: contentMaxWidth,
        margin: 'auto'
    },
    image: {
        maxWidth: "250px"
    },
    bgImage: {
        height: '100%'
    },
    shape1: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: "100%",
        // width: '100%'
    },
    banner: {
        height: '100%'
    },
    footerContainer: {
        position: 'relative',
        // maxWidth: containerMaxWidth,
        margin: 'auto',
        paddingTop: "1rem",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            gap: '20px'
        }
    },
    flexItem1: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
            width: "30%"
        }
    },
    flexItem2: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: "40%"
        },
        [theme.breakpoints.up('md')]: {
            width: "18%"
        }
    }
}))

function ContactCardRender({ icon, list = [], labelKey = '', valueKey = '', linkType = false }) {
    return <Grid container spacing={2} style={{ flexWrap: 'nowrap' }}>
        <Grid item >
            {icon}
        </Grid>
        <Grid item style={{ flex: 1 }} >
            <Grid container spacing={1}>
                {
                    list.map(item => {
                        return <Grid item xs={12} key={item[labelKey]}>
                            {
                                linkType ?
                                    <LinkComponent
                                        isAnchor={true}
                                        route={item[valueKey]}
                                        title={item[labelKey]}
                                        labelComponent="p"
                                        styles={{
                                            fontSize: "0.875rem"
                                        }}
                                    />
                                    :
                                    <Typography variant='body1' color='primary' style={{
                                        fontSize: '0.875rem',
                                        fontWeight: 600
                                    }}>{item[labelKey]}</Typography>
                            }
                        </Grid>
                    })
                }
            </Grid>
        </Grid>
    </Grid>
}

function ContactColumn({ contactDetails }) {
    const { phone, mail, address } = contactDetails
    return (
        <Grid container spacing={3}>
            {/* <Grid item xs={12}>
                <Typography variant='body1' component='p' color='secondary' style={{
                    fontSize: '1.3rem',
                    fontWeight: 600
                }}>Contact</Typography>
            </Grid> */}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ContactCardRender
                            list={address}
                            icon={<LocationIcon fontSize='small' color='primary' />}
                            labelKey='address'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ContactCardRender
                            list={mail}
                            icon={<MailIcon fontSize='small' color='primary' />}
                            labelKey='email'
                            valueKey='route'
                            linkType={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ContactCardRender
                            list={phone}
                            icon={<CallIcon fontSize='small' color='primary' />}
                            labelKey='phone'
                            valueKey='route'
                            linkType={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

function FooterColumn({ list = [], title = '', linkPrefix, valueKey, addLinkPrefix, showIcons = false }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='body1' component='p' color='secondary' style={{
                    fontSize: '1.3rem',
                    fontWeight: 600
                }}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {
                        list.map(item => {
                            const { id } = item
                            const route = `${!!linkPrefix ? `/${linkPrefix}/` : addLinkPrefix ? '/services/' : ''}${item[valueKey]}${!!linkPrefix ? `/` : ''}`
                            return (
                                <Grid item xs={12} key={id}>
                                    <LinkComponent {...item}
                                        route={route}
                                        labelComponent="p"
                                        styles={{
                                            fontSize: "0.875rem"
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        showIcons &&
                        <Grid item container alignItems="center" xs={12} spacing={2}>
                            <Grid item xs={4} sm={5} md={6}>
                                {socialIconList[facebookPartner]}
                            </Grid>
                            <Grid item xs={4} sm={5} md={6}>
                                {socialIconList[googlePartner]}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function Footer({
    contactDetails = {}, socialLinks = [], serviceList = {}, AppLinks = [], logo, showBg = true
}) {
    const classes = useStyles()
    const footerQuery = useStaticQuery(graphql`
    query FooterQuery {
        bannerImage: allFile(
            filter: {sourceInstanceName: {eq: "images"}, name: {eq: "Footer"}}
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
      }          
    `)
    const bannerImageData = footerQuery.bannerImage.edges[0].node
    return (
        <footer className={classes.footer}>
            {
                showBg &&
                <div className={classes.shape1}>
                    <GatsbyImage image={getImage(bannerImageData)} alt="Footer Banner" className={classes.banner} imgStyle={{
                        objectFit: 'fill'
                    }}
                    />
                </div>
            }
            <div className={classes.footerContainer}>
                <div className={clsx({ [classes.footerInnerContainer]: showBg, [classes.footerInnerContainer2]: !showBg })}>
                    <div className={classes.footerContainer}>
                        <div className={classes.flexItem1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Link to="/">
                                        <GatsbyImage alt="logo" image={getImage(logo)} className={classes.image} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <SocialLinksRender list={socialLinks} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactColumn contactDetails={contactDetails} />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.flexItem2}>
                            <FooterColumn
                                title="Services"
                                list={serviceList.links}
                                valueKey='slug'
                                linkPrefix={serviceList.linkPrefix}
                                addLinkPrefix={true}
                            />
                        </div>
                        <div className={classes.flexItem2}>
                            <FooterColumn
                                title="Useful Links"
                                list={AppLinks.filter(item => !!item.route)}
                                valueKey='route'
                                linkPrefix=""
                                addLinkPrefix={false}
                                showIcons={true}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className={classes.disclaimerContainer}>
                <Typography align='center' variant='body2' color='primary'>
                    All Rights Reserved - Vybinex
                </Typography>
            </div>
        </footer>
    )

}