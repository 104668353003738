import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { paletteConfig } from '../utitlities/Configurations'
// import CenturyGothic from './fonts/CenturyGothic.woff'

// const CenturyGothicFont = {
//     fontFamily: 'century-gothic',
//     fontStyle: 'normal',
//     fontDisplay: 'swap',
//     fontWeight: 400,
//     src: `
//     local('century-gothic'),
//     url(${CenturyGothic}) format('woff')
//   `,
// };

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200
    }
}

let Theme = createTheme({
    palette: {
        ...paletteConfig
    },
    breakpoints: breakpoints,
    typography: {
        fontFamily: '"Open Sans",sans-serif',
        h1: {
            fontSize: '3rem',
            fontWeight: '600'
        },
        h2: {
            fontSize: '2.5rem',
            fontWeight: '600'
        },
        h3: {
            fontSize: '2.3rem',
            fontWeight: '600'
        },
        h4: {
            fontSize: '2rem',
            fontWeight: '600'
        },
        h5: {
            fontSize: '1.8rem',
            fontWeight: '600'
        },
        h6: {
            fontSize: '1.3rem',
            fontWeight: '600'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: '600'
        },
        body2: {
            fontSize: '0.875rem',
        },
        button: {
            fontWeight: 600,
            fontSize: '1.2rem',
            textTransform: 'capitalize',
        }
    },
    // overrides: {
    //     MuiCssBaseline: {
    //         '@global': {
    //             '@font-face': [CenturyGothicFont],
    //         },
    //     }
    // },
})

Theme = responsiveFontSizes(Theme)

export default Theme