import React from 'react'
import { Link } from 'gatsby'
import { Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    activeLink: {
        color: `${theme.palette.secondary.main} !important`
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    button: {
        backgroundColor: "#ed2024 !important",
        color: '#ffffff !important',
        padding: "6px 16px",
        textDecoration: 'none',
        borderRadius: '4px',
        // '&:hover': {
        //     color: theme.palette.text.secondary,
        //     backgroundColor: theme.palette.secondary.main
        // }
    }
}))


export default function LinkComponent(props) {
    const classes = useStyles()
    const { route, title, styles, isAnchor, anchorTarget, labelVariant = 'body1', showAsButton, labelComponent = 'h3' } = props
    if (!!isAnchor) {
        return <a href={route} target={anchorTarget} className={classes.link} >
            <Typography variant={labelVariant} component={labelComponent} style={!!styles ? { fontWeight: 600, ...styles } : { fontWeight: 600 }}>
                {title}
            </Typography>
        </a>
    }
    return <Link to={route} className={classes.link} activeClassName={classes.activeLink}>
        <Typography variant={labelVariant} className={clsx({ [classes.button]: showAsButton })} component={labelComponent} style={!!styles ? { fontWeight: 600, ...styles } : { fontWeight: 600 }}>
            {title}
        </Typography>
    </Link>
}