import React from 'react'

export default function FacbookPartner() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1186.06 476" >
            <style type="text/css">
                {`   .st0{fill:#3C5A9A;}
                .st1{fill:#FFFFFF;}
                .st2{fill:#FEFEFF;}
                .st3{fill:#FEFEFE;}
                .st4{fill:#FDFDFE;}
                .st5{fill:#FDFEFE;}
                .st6{fill:#FBFBFD;}
                .st7{fill:#FAFBFC;}
                .st8{fill:#F6F7FA;}
                .st9{fill:#F4F5F9;}
                .st10{fill:#677CAE;}
                .st11{fill:#3D5A9A;}
                .st12{fill:#3D5B9A;}`}
            </style>
            <path class="st0" d="M592.8,472.97c-187.38,0-374.77,0-562.15,0c-18.8,0-26.64-7.9-26.64-26.84C4,307.41,4,168.69,4,29.98
            C4,11.22,12.11,3.01,30.76,3.01C405.53,3,780.29,3,1155.06,3.01c18.95,0,26.76,7.83,26.76,26.73c0,138.95-0.17,277.91,0.24,416.86
            c0.04,15.22-8.89,26.67-26.41,26.64C968.04,472.81,780.42,472.97,592.8,472.97z"/>
            <path class="st1" d="M233.17,100.21c39.84,0,79.68,0,119.53,0c13.72,0,19.03,5.17,19.03,18.65c0.02,79.42,0.01,158.85,0,238.27
            c0,13.68-5.25,19.01-18.77,19.03c-20.16,0.03-40.32-0.14-60.47,0.12c-4.96,0.06-6.96-0.72-6.91-6.42
            c0.31-31.53,0.26-63.06,0.04-94.59c-0.03-4.93,1.23-6.61,6.28-6.34c7.8,0.42,15.65-0.1,23.47,0.19c4.26,0.16,6.4-0.84,6.89-5.55
            c1.11-10.83,2.56-21.63,4.27-32.38c0.72-4.51-2.27-3.24-4.24-3.26c-10.43-0.11-20.87-0.2-31.3,0.02c-3.91,0.08-5.5-0.94-5.35-5.13
            c0.31-8.52-0.02-17.07,0.22-25.6c0.28-9.91,4.49-14.43,14.43-16.21c7.54-1.36,15.14-0.64,22.71-0.62c3.95,0.01,5.45-1.13,5.33-5.25
            c-0.26-9-0.12-18.02-0.06-27.02c0.02-2.62-0.32-4.93-3.57-4.97c-15.34-0.19-30.8-2.87-45.96,1.34
            c-21.24,5.9-34.51,23.01-35.62,46.06c-0.5,10.41-0.41,20.86-0.16,31.28c0.11,4.58-1.13,6.34-5.93,6.15
            c-8.52-0.34-17.07,0.04-25.61-0.16c-3.7-0.09-4.99,1.19-4.92,4.9c0.19,10.43,0.22,20.87-0.01,31.29c-0.1,4.27,1.74,5.18,5.54,5.1
            c8.53-0.19,17.08,0.1,25.61-0.13c3.99-0.11,5.33,1.17,5.31,5.26c-0.14,32.24-0.14,64.49,0,96.73c0.02,3.95-1.06,5.31-5.18,5.3
            c-42.45-0.14-84.9-0.02-127.35-0.15c-9.96-0.03-15.72-6.04-15.73-16.17c-0.07-81.08-0.07-162.16-0.01-243.25
            c0.01-10.94,5.76-16.44,16.83-16.45C152.06,100.19,192.61,100.21,233.17,100.21z"/>
            <path class="st2" d="M545.08,135.81c-9.55,24.95-19.22,49.86-28.52,74.9c-1.54,4.14-3.64,5.22-7.74,5.2
            c-4.25-0.02-6.77-0.87-8.45-5.4c-9.69-26.11-19.77-52.07-29.71-78.08c-0.76,0.02-1.53,0.03-2.29,0.05c0,12.02,0.06,24.03-0.02,36.05
            c-0.09,14.22-0.46,28.44-0.36,42.66c0.03,4.83-1.91,5.64-6.29,5.82c-5.21,0.22-5.04-2.51-4.92-6.07
            c1.01-29.59,1.89-59.19,3.07-88.77c0.11-2.67-1.9-7.6,3.73-7.45c4.9,0.13,10.49-2.5,13.34,5.33c9.52,26.21,19.79,52.16,29.78,78.2
            c0.51,1.32,1.09,2.61,2.2,5.27c5.75-15.13,11.1-29.14,16.42-43.17c5.2-13.7,10.69-27.31,15.46-41.16c2.66-7.72,8.53-4.13,13.01-4.37
            c4.8-0.26,3.35,4.28,3.45,6.99c1.08,29.83,1.99,59.66,3.03,89.49c0.12,3.51-0.08,6.05-4.73,5.61c-3.46-0.33-6.69,0.87-6.71-5.1
            c-0.08-24.4-0.79-48.8-1.27-73.19c-0.63-1.58-0.87-3.22-0.89-4.93C545.99,134.29,545.77,135.23,545.08,135.81z"/>
            <path class="st2" d="M1076.55,151.42c1.44,3.49,2.76,6.02,3.54,8.71c2.87,9.89,0.9,18.96-6.2,26.32
            c-7.6,7.87-17.23,10.65-27.94,8.19c-3.72-0.85-6.79-1.29-9.84,1.49c-2.31,2.1-3.59,4.4-2.88,7.5c0.68,2.97,3.04,4.24,5.72,4.57
            c4.92,0.6,9.88,1.06,14.84,1.25c6.85,0.26,13.68-0.13,20.48,1.54c16.14,3.97,21.38,19.29,10.84,32.25
            c-3.21,3.95-7.26,6.86-11.81,8.93c-12.37,5.62-25.37,6.1-38.22,2.59c-8.9-2.43-16.8-7.14-17.97-17.79
            c-1.15-10.42,5.16-16.09,14.34-20.58c-5.45-3.04-9.16-6.83-8.3-13.23c0.86-6.45,5.7-9.46,11.04-12.7
            c-9.97-8.03-13.2-18.45-10.13-30.41c3.83-14.93,20.13-24.16,36.02-19.49c8.63,2.54,16.88,3.65,25.56,2.16
            c5.18-0.89,5.74,1.74,5.88,6c0.19,5.66-3.5,3.75-6.19,3.66C1082.74,152.33,1080.18,151.85,1076.55,151.42z"/>
            <path class="st3" d="M458.34,310.03c0-14.46,0.32-28.92-0.15-43.36c-0.2-6.14,1.84-7.71,7.58-7.23c10.12,0.84,20.33-0.87,30.46,1.25
            c18.98,3.97,28.9,14.93,28.21,31.67c-0.77,18.58-12.55,30.66-32.02,32.56c-6.12,0.6-12.3,0.9-18.44,0.83
            c-3.91-0.05-4.73,1.52-4.65,5.03c0.2,8.76-0.18,17.54,0.16,26.3c0.18,4.62-2.17,4.77-5.65,4.81c-3.63,0.04-5.66-0.49-5.59-4.94
            C458.48,341.31,458.34,325.67,458.34,310.03z"/>
            <path class="st4" d="M722.35,180.02c0,11.9-0.25,21.74,0.11,31.55c0.18,4.74-1.78,5.38-5.84,5.39c-3.98,0.01-5.23-1.08-5.21-5.16
            c0.15-33.2,0.3-66.4-0.09-99.59c-0.08-6.76,4.34-6.7,8.15-7.01c4.93-0.39,2.73,4.09,2.76,6.26c0.2,20.61,0.12,41.23,0.12,62.12
            c3.48,0.06,4.67-2.66,6.35-4.3c6.93-6.81,14.04-13.5,20.33-20.87c5.03-5.89,10.66-8.06,18.16-6.28c0.43,2.83-2.26,3.79-3.77,5.31
            c-8.5,8.59-16.96,17.25-25.84,25.43c-3.78,3.49-4.22,5.48-0.39,9.38c10.61,10.8,20.7,22.09,31.65,33.9
            c-7.44,1.19-13.06,1.57-18.17-4.7C742.22,201.08,732.8,191.51,722.35,180.02z"/>
            <path class="st3" d="M579.59,158.02c-4.03-9.11-3.8-9.75,4.16-13.36c7.66-3.47,15.63-5.24,24.11-4.87
            c14.59,0.64,23.27,9.14,23.56,23.85c0.24,12.09,0.04,24.18,0.07,36.27c0.01,4.26-0.56,8.71,5.58,9.72c3.21,0.53,1.35,3.46,1.5,5.31
            c0.2,2.39-1.23,3.22-3.55,3.27c-6.84,0.16-11.06-3.42-13.9-9.81c-7.09,7.46-15.4,11.01-25.59,9.97c-8.83-0.9-17.22-7.08-19.09-14.65
            c-2.39-9.65,0.78-18.17,8.89-23.59c9.21-6.14,19.91-7.32,30.46-8.59c4.04-0.49,5.1-1.79,5.04-5.63
            c-0.16-10.21-5.37-15.81-15.65-16.22C595.77,149.31,587.69,153.18,579.59,158.02z"/>
            <path class="st3" d="M813.48,218.37c-26.32,1.55-40.58-21.43-37.33-44.68c2.23-15.92,9.64-28.19,26.23-32.68
            c10.42-2.82,20.38-1.69,28.5,6c8.51,8.06,8.34,18.56,7.06,29.17c-0.44,3.64-3.07,3.54-5.79,3.54c-13.28,0-26.56,0.31-39.83-0.01
            c-5.1-0.12-5.9,1.75-5.32,6.17c2.26,17.23,18.01,26.73,36.07,21.56c2.04-0.58,3.96-1.77,6.03-2.04c1.88-0.24,4.2-4.47,5.6-0.32
            c1.07,3.19,2.91,7.35-2.53,9.52C825.93,217.1,819.64,218.88,813.48,218.37z"/>
            <path class="st3" d="M861.91,348.34c3.79,8.68,3.47,9.38-6.14,12.83c-19.73,7.1-39.33-0.13-47.82-17.64
            c-9.16-18.88-2.69-44.32,14.15-54.13c11-6.41,22.59-7.14,33.6-0.12c10.86,6.93,11.97,17.94,10.9,29.55c-0.4,4.3-2.27,5.96-6.88,5.87
            c-13.26-0.26-26.54,0.17-39.8,0c-4.25-0.05-5.07,1.35-4.65,5.35c1.8,17.16,16.72,27.06,34.7,22.56
            C854.04,351.59,857.93,349.79,861.91,348.34z"/>
            <path class="st3" d="M538.47,302.69c-3.93-9.17-3.83-9.28,4.92-13.36c7.68-3.58,15.7-5.09,24.15-4.56
            c14.17,0.89,22.3,8.82,22.73,23.04c0.38,12.79,0.19,25.6,0.11,38.39c-0.02,3.84,0.28,7.08,4.95,8.03c4.12,0.84,2.09,4.17,2.08,6.4
            c-0.02,3.31-2.84,2.57-4.81,2.46c-6.07-0.35-10.08-3.67-12.17-9.94c-10.19,9.89-21.3,13.7-34.02,7.66
            c-14.85-7.05-16.16-25.89-2.64-35.41c9.05-6.37,19.72-7.89,30.31-8.8c5.17-0.44,6.41-2.17,5.83-6.99
            c-1.3-10.86-7.72-16.28-19.01-15.04c-3.95,0.43-7.91,1.54-11.66,2.89C545.71,298.72,542.45,300.72,538.47,302.69z"/>
            <path class="st5" d="M737.05,295.56c4.72-2.97,8.31-5.71,12.29-7.67c19.25-9.45,36.67,0.79,37.24,22.12
            c0.42,15.64-0.06,31.3,0.18,46.95c0.07,4.47-1.95,4.95-5.58,4.94c-3.59-0.01-5.68-0.4-5.59-4.91c0.26-14.46,0.2-28.93,0.07-43.39
            c-0.15-16.37-9.21-22.59-24.8-17.22c-11.16,3.85-13.78,7.42-13.78,18.8c0,13.52-0.05,27.03,0.03,40.55
            c0.02,3.55,0.29,6.37-4.94,6.26c-4.54-0.09-5.51-1.69-5.48-5.84c0.16-21.34,0.31-42.69-0.07-64.02c-0.11-6.04,3.58-6.18,7.48-6.99
            c2.2-0.46,3.02,0.57,2.98,2.68C737.01,290.17,737.05,292.53,737.05,295.56z"/>
            <path class="st2" d="M955.44,150.76c8.58-5.9,15.91-11.04,25.49-10.98c13.72,0.09,23.24,8.44,23.6,22.06
            c0.44,16.81,0.02,33.64,0.24,50.46c0.05,4.03-1.66,4.55-5.06,4.63c-3.78,0.08-5.47-0.69-5.39-5c0.26-13.97,0.14-27.96,0.08-41.94
            c-0.04-11.44-3.29-17.32-10.63-19.56c-7.79-2.38-22.73,2.53-27.19,8.86c-1.52,2.15-1.09,4.54-1.1,6.87
            c-0.04,14.93-0.19,29.86,0.08,44.78c0.08,4.56-1.13,5.97-5.87,6.08c-5.39,0.13-4.81-2.86-4.8-6.24c0.03-21.09,0.36-42.18-0.18-63.26
            c-0.18-7.17,4.65-6.3,8.53-7.35c1.92-0.52,2.16,1.28,2.18,2.75C955.47,145.02,955.44,147.11,955.44,150.76z"/>
            <path class="st4" d="M685.43,279.76c-2.6,7.08,1.96,7.79,8.18,7.26c3.77-0.32,7.58-0.04,11.38-0.06c2.66-0.01,5.31-0.38,5.02,3.82
            c-0.23,3.35-0.24,6.09-4.89,5.78c-4.96-0.32-9.97,0.17-14.93-0.14c-4.26-0.27-5.72,1.05-5.73,5.52c-0.04,11.6-0.38,23.21-1.12,34.78
            c-0.97,15.09,4.97,20.21,19.57,16.29c2.49-0.67,5.44-3.83,6.9,1.57c1.24,4.58-0.6,6.47-4.7,7.62c-6.31,1.77-12.72,1.96-19.01,0.74
            c-7.96-1.54-12.83-9.37-12.74-20.57c0.1-12.78,0.54-25.57,1.28-38.34c0.32-5.55-0.77-8.6-7.09-7.74c-2.07,0.28-4.35,0.12-4.37-3.14
            c-0.01-2.52,0.97-4.6,3.34-4.64c9.56-0.17,8.78-6.91,8.93-13.27c0.12-5.07,3.8-7.43,8.31-5.49
            C685.69,270.6,685.69,270.6,685.43,279.76z"/>
            <path class="st3" d="M858.64,178.99c0-7.1-0.23-14.2,0.08-21.29c0.19-4.36-0.74-6.76-5.71-6.4c-1.99,0.14-5.01,1.17-5.21-2.84
            c-0.14-2.88,1.01-4.9,3.73-4.95c8.79-0.16,8.42-6.09,8.06-12.13c-0.23-3.83-0.24-7.04,5.29-6.77c3.62,0.18,4.79,1.07,4.87,4.86
            c0.25,12.83,0.42,12.83,13.51,12.83c2.13,0,4.27,0.08,6.39-0.01c2.77-0.11,4.46,0.45,4.15,3.91c-0.26,3.03-0.06,5.73-4.4,5.37
            c-3.06-0.26-6.15-0.04-9.23-0.04c-11.08,0-11.29-0.01-11.71,11.24c-0.43,11.59-0.54,23.19-0.53,34.79
            c0.01,9.24,5.09,13.23,14.31,11.56c2.32-0.42,4.58-1.13,6.86-1.74c1.77-0.48,4.16-1.75,4.27,1.34c0.08,2.26,3.46,5.03-0.18,6.88
            c-7.98,4.06-16.54,4.33-24.86,1.74c-6.52-2.02-9.64-7.82-10.12-14.23c-0.6-8-0.14-16.08-0.14-24.13
            C858.26,178.99,858.45,178.99,858.64,178.99z"/>
            <path class="st6" d="M664.21,153.94c8.04-11.64,16.89-16.64,29.47-13.02c5.24,1.51,5.33,3.88,4.07,8.23
            c-0.93,3.24-2.06,3.24-4.93,2.37c-14.46-4.35-28.42,6.08-28.58,21.28c-0.13,12.79-0.18,25.59,0.06,38.38
            c0.08,4.15-0.79,5.88-5.41,5.8c-4.31-0.07-5.66-1.18-5.61-5.61c0.23-21.32,0.4-42.65-0.06-63.97c-0.16-7.21,4.88-6.32,8.78-7.25
            c2.44-0.58,2.16,1.86,2.2,3.43C664.25,146.34,664.21,149.09,664.21,153.94z"/>
            <path class="st6" d="M655.18,297.29c-18.83-5.78-32.08,4.1-32.08,23.91c0,11.59-0.11,23.18,0.06,34.77c0.06,3.85-0.32,6-5.19,6.04
            c-5.03,0.04-5.84-1.96-5.81-6.28c0.16-20.82,0.38-41.64-0.11-62.45c-0.15-6.42,3.2-7.93,7.88-8.04c5.99-0.14,2.23,4.81,3.1,7.35
            c0.43,1.26,0.07,2.79,0.07,4.35c2.89-0.19,3.28-2.71,4.57-4.13c5.45-6.05,12.05-8.85,20.23-8.02
            C658.01,285.82,659.01,287.46,655.18,297.29z"/>
            <path class="st7" d="M896.3,298.76c8.03-11.89,17.19-16.52,29.59-12.95c4.26,1.23,5.78,3.04,3.96,7.63
            c-1.23,3.09-2.11,3.87-5.48,2.87c-14.03-4.18-27.91,6.36-28.04,21.14c-0.11,13.02-0.14,26.05,0.04,39.07
            c0.05,3.84-0.87,5.46-5.1,5.44c-4.2-0.02-5.83-1.08-5.79-5.56c0.2-21.31,0.32-42.63-0.06-63.93c-0.11-6.31,3.7-7.17,7.91-7.24
            c5.45-0.09,2.12,4.54,2.9,6.97C896.63,293.47,896.3,294.99,896.3,298.76z"/>
            <path class="st8" d="M921.72,178.53c0,11.14-0.19,22.28,0.09,33.41c0.11,4.37-1.74,5.03-5.47,5.01c-3.65-0.02-5.54-0.5-5.49-4.94
            c0.22-21.8,0.33-43.6-0.04-65.39c-0.11-6.6,4.83-5.91,8.05-6.29c4.98-0.59,2.68,4.04,2.74,6.23
            C921.87,157.2,921.72,167.87,921.72,178.53z"/>
            <path class="st9" d="M909.18,117.5c0.32-4.33,2.39-7.35,7.38-7.28c4.65,0.07,6.83,3.11,6.88,7.3c0.06,4.62-2.68,7.85-7.32,7.71
            C911.97,125.13,909.39,122.25,909.18,117.5z"/>
            <path class="st10" d="M545.08,135.81c-0.34-2.02,0.97-3.22,2.01-5.39c1.18,3.32,0.83,5.75,0.48,8.17
            C546.74,137.67,545.91,136.74,545.08,135.81z"/>
            <path class="st11" d="M1052.45,248.31c-6.83-0.22-12.85-1.19-18.28-4.37c-4.87-2.85-7.8-6.87-6.78-12.82
            c0.9-5.31,8.49-12.38,15.08-12.18c9.38,0.29,18.94-0.72,28.18,1.76c10.37,2.79,12.82,11.54,5.28,19.18
            C1069.33,246.56,1060.7,247.87,1052.45,248.31z"/>
            <path class="st11" d="M1070.63,167.28c0.08,11.56-6.93,19.24-17.82,19.5c-11.23,0.27-19.4-7.65-19.42-18.83
            c-0.02-11.5,7.73-19.52,18.81-19.46C1063.43,148.55,1070.55,155.8,1070.63,167.28z"/>
            <path class="st0" d="M469.39,293.77c0-6.97,0.37-12.67-0.11-18.31c-0.45-5.24,1.64-6.49,6.41-6.05c5.85,0.54,11.74-0.27,17.63,1.09
            c13.57,3.15,19.88,9.88,19.76,21.87c-0.12,11.91-6.82,19.51-19.68,22.3c-0.69,0.15-1.4,0.25-2.11,0.27
            c-7.04,0.21-16.61,3.41-20.46-0.61C466.25,309.57,470,299.96,469.39,293.77z"/>
            <path class="st11" d="M620.75,190.32c0.02,13.92-11.76,22.42-25,18.14c-4.98-1.61-8.13-4.9-8.97-9.96
            c-0.86-5.15,1.39-9.2,5.66-12.09c7.72-5.23,16.76-6.01,25.58-7.56c2.44-0.43,2.72,1.17,2.73,2.96
            C620.76,184.65,620.75,187.48,620.75,190.32z"/>
            <path class="st11" d="M807.71,171.29c-5.44,0-10.88-0.1-16.32,0.04c-3.02,0.08-4.17-0.5-3.41-3.98
            c2.68-12.29,16.22-20.77,28.27-17.34c3.75,1.07,6.95,3.11,8.95,6.54c2.3,3.94,4.58,8.57,2.68,12.73c-1.84,4.05-6.74,1.38-10.24,1.79
            c-3.27,0.38-6.62,0.08-9.93,0.08C807.71,171.21,807.71,171.25,807.71,171.29z"/>
            <path class="st12" d="M836.38,316.28c-5.42,0-10.85-0.12-16.26,0.04c-3.23,0.1-4.65-0.39-3.77-4.29
            c2.85-12.57,16.92-21.02,28.82-16.95c3.94,1.34,7.02,3.74,8.95,7.5c5.36,10.42,3.58,13.49-7.84,13.49c-3.3,0-6.6,0-9.9,0
            C836.38,316.15,836.38,316.22,836.38,316.28z"/>
            <path class="st12" d="M580.05,334.92c0.01,13.72-10.81,21.96-24.4,18.65c-5.36-1.31-8.85-4.47-9.68-10
            c-0.82-5.44,1.21-9.69,5.92-12.65c7.66-4.82,16.44-5.69,25.04-7.2c2.7-0.47,3.17,1.22,3.13,3.39
            C580.02,329.71,580.05,332.31,580.05,334.92z"/>
        </svg>


    )
}