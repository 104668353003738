import React from 'react'
import Facebook from '../components/General/SocialIcons/Facebook'
import Instagram from '../components/General/SocialIcons/Instagram'
import LinkedIn from '../components/General/SocialIcons/LinkedIn'
import Whatsapp from '../components/General/SocialIcons/Whatsapp'
import Twitter from '../components/General/SocialIcons/Twitter'
import Snapchat from '../components/General/SocialIcons/Snapchat'
import Seo from '../components/General/ServiceIcons/Seo'
import Sem from '../components/General/ServiceIcons/Sem'
import Smm from '../components/General/ServiceIcons/Smm'
import Influence from '../components/General/ServiceIcons/Influencer'
import Web from '../components/General/ServiceIcons/Web'
import Video from '../components/General/ServiceIcons/Video'
import Youtube from '../components/General/SocialIcons/Youtube'
import Branding from '../components/General/ServiceIcons/Branding'
import Photo from '../components/General/ServiceIcons/Photo'
import Quora from '../components/General/SocialIcons/Quora'
import Training from '../components/General/ServiceIcons/Training'
import GooglePartner from '../components/General/SocialIcons/GooglePartner'
import FacebookPartner from '../components/General/SocialIcons/FacebookPartner'
import { facebookConstant, traniningConstant, quoraConstant, facebookPartner, googlePartner, instagramConstant, youtubeConstant, linkedinConstant, whatsappConstant, snapchatConstant, twitterConstant } from './Configurations'
import { influenceConstant, photoConstant, semConstant, seoConstant, brandingConstant, socialMediaConstant, webConstant, videoConstant } from './Configurations'

export const socialIconList = {
    [facebookConstant]: <Facebook />,
    [linkedinConstant]: <LinkedIn />,
    [instagramConstant]: <Instagram />,
    [whatsappConstant]: <Whatsapp />,
    [twitterConstant]: <Twitter />,
    [snapchatConstant]: <Snapchat />,
    [youtubeConstant]: <Youtube />,
    [quoraConstant]: <Quora />,
    [googlePartner]: <GooglePartner />,
    [facebookPartner]: <FacebookPartner />
}


export const serviceIconList = {
    [seoConstant]: <Seo />,
    [semConstant]: <Sem />,
    [socialMediaConstant]: <Smm />,
    [webConstant]: <Web />,
    [influenceConstant]: <Influence />,
    [photoConstant]: <Photo />,
    [videoConstant]: <Video />,
    [brandingConstant]: <Branding />,
    [traniningConstant]: <Training />
}