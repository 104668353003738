import React, {useState, useEffect} from 'react'
import { ThemeProvider, makeStyles, CssBaseline, Hidden } from '@material-ui/core'
import Seo from './Seo'
import Theme from '../gatsby-theme-material-ui-top-layout/theme.js'
import { seoValuesReturn } from '../utitlities/helperFunctions'
import Header from './Header'
import './layout.css'
import Footer from './Footer'
import DrawerComponent from './DrawerComponent'
import { graphql, useStaticQuery } from 'gatsby'
import SocialLinksRender from './General/SocialLinksRender' 
import WhatsappPopUpRender from './General/WhatsappPopUpRender' 
import TrainingPopup from './Home/TraningPopup'
import { fetchData } from '../utitlities/helperFunctions'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    // paddingTop: "76px",
  },
  socialDiv: {
    position: 'fixed',
    top: '50vh',
    transform: "translateY(-50%)"
  }
}))


export default function Layout({ showFooterBg = true, pageSeo = { title: '', description: "", schemaTag: [] }, showContact = true, ...props }) {
  const layoutQuery = useStaticQuery(graphql`
    query LayoutQuery {
        links: allApiLinks {
          edges {
            node {
              links {
                header
                order
                route
                title
              }
            }
          }
        }
        services: allApiServices {
          edges {
            node {
              
              links {
                title
                order
                slug
              }
            }
          }
        }
          contact: allApiContact {
            edges {
              node {
                address {
                  address
                  order
                }
                mail {
                  email
                  order
                  route
                }
                phone {
                  order
                  phone
                  route
                }
              }
            }
          }
          social: allApiSocialLinks {
            edges {
              node {
                links {
                  order
                  url
                  id
                  float
                }
              }
            }
          }
          logo: allFile(filter: {sourceInstanceName: {eq: "images"}, name: {eq: "logo"}}) {
            edges {
              node {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
      }
    `)

 const [LinksData,SetLink] = useState()  
 const [ServiceData,SetServiceData] = useState()
 const [SocialData,SetSocialData] = useState()  
 const [WhatappNoData,SetWhatappNoData] = useState()  
 const [ContactData,SetContactData] = useState()     
    useEffect(() => { 
      fetchData(`links`)        
      .then(x => SetLink(x) ) 
      fetchData(`services`)
      .then(x => SetServiceData(x) )
      fetchData(`social-links`)
      .then(x => SetSocialData(x) )
      fetchData(`social-links`)
      .then(x => SetWhatappNoData(x))
      fetchData(`contact`)
      .then(x => SetContactData(x) )         
  }, []);  
  const allLinks = LinksData ? LinksData.links : layoutQuery.links.edges[0].node.links
  const serviceLinks = ServiceData ? ServiceData.links :  layoutQuery.services.edges[0].node.links
  const serviceLinkPrefix = ''
  const contactLinks = ContactData ? ContactData : layoutQuery.contact.edges[0].node
  const socialLinks = SocialData ? SocialData.links : layoutQuery.social.edges[0].node.links
  const whatsAppNo = WhatappNoData ? WhatappNoData.whatsapp : layoutQuery.social.edges[0].node.whatsapp
  let floatingLinks = SocialData ? socialLinks.filter(item => item.float) : []
  const logo = layoutQuery.logo.edges[0].node.childImageSharp.original.src
  const classes = useStyles()
  const seoValues = seoValuesReturn(pageSeo)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [socialLinkOffset, setSocialLinkOffset] = React.useState(10)
  const [openPopup, setOpenPopup] = React.useState(false)

  const combinedLinks = allLinks.map(item => {
    if (item.title === "Services") {
      return {
        ...item, subRoutes: {
          linkPrefix: serviceLinkPrefix,
          links: serviceLinks
        }
      }
    } else return item
  })

  React.useEffect(() => {
    let timer;
    let screenResize = () => {
      let screenWidth = window.innerWidth
      let screenWidthHalf = screenWidth / 2
      if (screenWidthHalf > 700) {
        setSocialLinkOffset(screenWidthHalf - 700 + 20)
      } else {
        setSocialLinkOffset(20)
      }
    }
    screenResize()
    window.addEventListener("resize", screenResize)
    if (!window.sessionStorage.getItem('popup-seen')) {
      timer = setTimeout(() => {
        setOpenPopup(true)
      }, 10000)
    }
    return () => {
      window.removeEventListener("resize", screenResize)
      !!timer && clearTimeout(timer)
    }
  }, [])

  const appBarLinks = combinedLinks.filter(item => item.header)

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Seo {...seoValues} />
      <Header AppLinks={appBarLinks} logo={logo} toggleDrawer={() => setOpenDrawer(!openDrawer)} />
      <DrawerComponent
        open={openDrawer}
        AppLinks={appBarLinks}
        onClose={() => setOpenDrawer(false)}
      />
      <div className={classes.mainContainer}>
        <main>
          {props.children}
        </main>
      </div>
      <Footer
        showBg={showFooterBg}
        logo={logo}
        contactDetails={contactLinks}
        socialLinks={socialLinks}
        serviceList={{
          linkPrefix: serviceLinkPrefix,
          links: serviceLinks
        }}
        AppLinks={combinedLinks}
      />
      <TrainingPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
      <Hidden smDown>
        <div className={classes.socialDiv} style={{
          right: socialLinkOffset
        }}>
          <SocialLinksRender list={floatingLinks} isFloat={true} />
        </div>
      </Hidden>
      <WhatsappPopUpRender whatsAppNo={whatsAppNo} />

    </ThemeProvider>
  )
}
