import React from 'react'
import { Typography, Grid } from '@material-ui/core'


// const useStyles = makeStyles(theme => ({
//     titlePadding: {
//         paddingBottom: "3rem"
//     }
// }))

export default function TitleComponent({
    title = '', titleColor = 'primary', secondaryTitles = [], smallTitle = '', subHeading = '', smallTitleColor = 'primary', isFullWidth = false,
    titleVariant = 'h2', titleAlign = 'center', titleComponent = 'h2', subHeadingVariant = 'body2', titleSpacing = 4, subHeadingColor = '#6c6e75'
}) {
    // const classes = useStyles()
    return <Grid container justifyContent={titleAlign === 'center' ? 'center' : "flex-start"} spacing={titleSpacing}>
        <Grid item xs={12}>
            <Grid container justifyContent={titleAlign === 'center' ? 'center' : "flex-start"} spacing={1}>
                {
                    !!smallTitle &&
                    <Grid item xs={12}>
                        <Typography style={{ textTransform: 'uppercase', fontWeight: 600 }} color={smallTitleColor} variant="body1" align={titleAlign} >
                            {smallTitle}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} md={isFullWidth ? 12 : 8}>
                    <Typography color={titleColor} variant={titleVariant} component={titleComponent} align={titleAlign} >
                        {
                            title.split(' ').map(text => {
                                if (secondaryTitles.includes(text)) {
                                    return <Typography key={text} component='span' variant={titleVariant} color='secondary' >{` ${text}`}</Typography>
                                }
                                return ` ${text}`
                            })
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        {
            !!subHeading &&
            <Grid item xs={12} sm={10} md={isFullWidth ? 12 : 8}>
                <Typography style={{ color: subHeadingColor, fontWeight: 600 }} variant={subHeadingVariant} align={titleAlign} >
                    {subHeading}
                </Typography>
            </Grid>
        }
    </Grid>
}