import React from 'react'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import { contactFormId, contactFormDivId, contentMaxWidth } from '../../utitlities/Configurations'
import TitleComponent from '../General/TitleComponent'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        maxWidth: contentMaxWidth,
        margin: 'auto'
    },
    paddingClass: {
        padding: "1rem 1rem 2rem",
        [theme.breakpoints.up("sm")]: {
            padding: "4rem 2rem 0px"
        }
    },
    titlePadding: {
        paddingBottom: "2rem",
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '3rem'
        }
    },
    wrappedForm: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '4px',
        padding: '20px',
        [theme.breakpoints.up('sm')]: {
            padding: '35px'
        }
    }
}))

export default function Form({
    title = 'Get In Touch', showTitle = false, secondaryTitles = [], smallTitle = '', subHeading = '', showFullWidth = false,
    titleVariant = 'h6', titleComponent = 'p', formDivId = contactFormDivId, formId = contactFormId, description = '', wrapWholeDiv = false, downloadLink = ''
}) {
    const classes = useStyles()
    React.useEffect(() => {
        const loadForm = () => {
            if ('hbspt' in window) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "25503956",
                    formId: formId,
                    target: `#${formDivId}`,
                    onFormSubmit: (form) => {
                    },
                    onFormSubmitted: (form) => {
                        if (formDivId == 'freeDownloadForm') {
                            const fileUrl = downloadLink;
                            const link = document.createElement('a');
                            link.href = fileUrl;
                            link.download = 'file.pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    },
                })
            } else {
                setTimeout(loadForm, 500)
            }
        }
        loadForm()

    }, [formDivId, formId])

    return <div id='form-div' className={clsx(classes.container, { [classes.paddingClass]: secondaryTitles.length !== 0, [classes.wrappedForm]: wrapWholeDiv })}>
        <Grid container spacing={3}>
            {
                showTitle &&
                <Grid item xs={12}>
                    {
                        secondaryTitles.length !== 0 ?
                            <div className={classes.titlePadding}>
                                <TitleComponent
                                    title={title}
                                    secondaryTitles={secondaryTitles}
                                    titleVariant={titleVariant}
                                    titleComponent={titleComponent}
                                    smallTitle={smallTitle}
                                    subHeading={subHeading}
                                />
                            </div> :
                            <Typography color='primary' variant={titleVariant} component={titleComponent}>{title}</Typography>
                    }

                </Grid>
            }
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={2}>
                    {
                        !!description &&
                        <Grid item xs={12} md={6}>
                            <Typography>{description}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={!!description ? 6 : showFullWidth ? 12 : 8} >
                        <div className={clsx({ [classes.wrappedForm]: !!description })} id={formDivId}>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
}