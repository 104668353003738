import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        width: "100%",
        height: "0px",
        paddingBottom: "56.25%",
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '6px',
        border: 'none'
    }
}))

export default function VideoComponent({ url = '', title = '', className, styles = {} }) {
    const classes = useStyles()
    return <div className={classes.container}>
        <iframe src={url} allowFullScreen title={title} autoplay={"false"} className={clsx(classes.iframe, className)} style={styles} />
    </div>
}