import React from 'react'
import { AppBar, makeStyles, Hidden, Grid, Typography } from '@material-ui/core'
import { contentMaxWidth } from '../utitlities/Configurations'
import LinkComponent from './Gatsby/LinkComponent'
import IconButtonComponent from './General/Buttons/IconButton'
import MenuIcon from '@material-ui/icons/Menu' 
import { Link } from 'gatsby'
import MenuComponent from './General/HoverMenuComponent'
import {
    usePopupState,
    bindHover
} from 'material-ui-popup-state/hooks'

const useStyles = makeStyles(theme => ({
    container: {
        margin: 'auto',
        width: '100%',
        maxWidth: contentMaxWidth,
        padding: "1rem",
        [theme.breakpoints.up('sm')]: {
            padding: "1rem 2rem"
        }
    },
    appbarFixed: {
        backgroundColor: 'transparent'
    },
    image: {
        maxWidth: '150px'
    },
    menuPaperClassName: {
        boxShadow: "0 2px 29px rgb(0 0 0 / 5%)",
        padding: "1.5rem 2rem",
        maxWidth: "300px",
        borderBottom: `5px solid ${theme.palette.primary.main}`
    }
}))


function LinkTab(tab) {
    const { subRoutes, id } = tab
    const classes = useStyles()
    const popupState = usePopupState({
        variant: 'popover',
        popupId: id,
    })
    if (!!subRoutes && !!subRoutes.links && subRoutes.links.length !== 0) {
        return <Grid item {...bindHover(popupState)}>
            {
                !!tab.route ?
                    <LinkComponent {...tab}
                        labelComponent='p'
                    />
                    :
                    <Typography variant={"body1"} component={"p"} style={{ fontWeight: 600 }} color='primary'>
                        {tab.title}
                    </Typography>
            }

            <MenuComponent menuPaperClassName={classes.menuPaperClassName} popupState={popupState}>
                <Grid container spacing={3}>
                    {
                        subRoutes.links.map(item => {
                            const route = `${!!subRoutes.linkPrefix ? `/${subRoutes.linkPrefix}/` : '/services/'}${item.slug}${!!subRoutes.linkPrefix ? `/` : ''}`
                            return <Grid item xs={12} key={item.id}>
                                <LinkComponent {...item}
                                    route={route}
                                    labelComponent='p'
                                    styles={{
                                        fontSize: '0.875rem'
                                    }}
                                />
                            </Grid>
                        })
                    }
                </Grid>
            </MenuComponent>
        </Grid>
    }
    return <Grid item >
        <LinkComponent {...tab}
            labelComponent='p'
        />
    </Grid>
}

export default function Header({
    toggleDrawer, AppLinks = [], logo
}) {
    const classes = useStyles()
    const [scrolled, setScrolled] = React.useState(false)

    React.useEffect(() => {
        const eventFunction = () => {
            if (window.pageYOffset > 10) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        eventFunction()
        window.addEventListener("scroll", eventFunction)
        return () => {
            window.removeEventListener('scroll', eventFunction)
        }
    }, [])

    return <AppBar
        elevation={scrolled ? 4 : 0}
        style={{
            backgroundColor: scrolled ? 'white' : 'transparent'
        }}
    >
        <div className={classes.container}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <Link to='/'>
                        <img alt='logo' className={classes.image} src={logo} />
                    </Link>
                </Grid>
                <Grid item>
                    <Hidden smDown implementation='css'>
                        <Grid container spacing={4} style={{ width: '100%', margin: '0px' }} alignItems='center'>
                            {
                                AppLinks.map(item => {
                                    const { id } = item
                                    return <LinkTab key={id} showAsButton={item.title === 'Marketing Secrets'} {...item} />
                                })
                            }
                        </Grid>
                    </Hidden>
                    <Hidden mdUp implementation='css'>
                        <IconButtonComponent id='toggle-menu' onClick={toggleDrawer}>
                            <MenuIcon color='primary' />
                        </IconButtonComponent>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    </AppBar>
}