import React from 'react'

export default function Sem() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 60 60" version="1.1">
            <g id="surface1">
                <path d="M 59 0 L 1 0 C 0.445312 0 0 0.445312 0 1 L 0 47 C 0 47.554688 0.445312 48 1 48 L 23 48 L 23 58 L 20 58 L 20 60 L 36 60 L 36 58 L 25 58 L 25 48 L 35 48 L 35 46 L 2 46 L 2 38 L 35 38 L 35 36 L 6 36 L 6 6 L 54 6 L 54 22 L 56 22 L 56 5 C 56 4.445312 55.554688 4 55 4 L 5 4 C 4.445312 4 4 4.445312 4 5 L 4 36 L 2 36 L 2 2 L 58 2 L 58 22 L 60 22 L 60 1 C 60 0.445312 59.554688 0 59 0 Z M 59 0 " />
                <path d="M 59 26 L 39 26 C 38.445312 26 38 26.445312 38 27 L 38 59 C 38 59.554688 38.445312 60 39 60 L 59 60 C 59.554688 60 60 59.554688 60 59 L 60 27 C 60 26.445312 59.554688 26 59 26 Z M 58 28 L 58 32 L 40 32 L 40 28 Z M 58 34 L 58 53 L 40 53 L 40 34 Z M 40 58 L 40 55 L 58 55 L 58 58 Z M 40 58 " />
                <path d="M 51 29 L 47 29 L 47 31 L 51 31 Z M 51 29 " />
                <path d="M 43 44 L 55 44 C 55.554688 44 56 43.554688 56 43 L 56 37 C 56 36.445312 55.554688 36 55 36 L 43 36 C 42.445312 36 42 36.445312 42 37 L 42 43 C 42 43.554688 42.445312 44 43 44 Z M 44 38 L 54 38 L 54 42 L 44 42 Z M 44 38 " />
                <path d="M 56 46 L 42 46 L 42 48 L 56 48 Z M 56 46 " />
                <path d="M 56 49 L 42 49 L 42 51 L 56 51 Z M 56 49 " />
                <path d="M 52 19 L 52 9 C 52 8.445312 51.554688 8 51 8 L 9 8 C 8.445312 8 8 8.445312 8 9 L 8 19 C 8 19.554688 8.445312 20 9 20 L 51 20 C 51.554688 20 52 19.554688 52 19 Z M 50 18 L 10 18 L 10 10 L 50 10 Z M 50 18 " />
                <path d="M 9 22 C 8.445312 22 8 22.445312 8 23 L 8 33 C 8 33.554688 8.445312 34 9 34 L 21 34 C 21.554688 34 22 33.554688 22 33 L 22 23 C 22 22.445312 21.554688 22 21 22 Z M 20 32 L 10 32 L 10 24 L 20 24 Z M 20 32 " />
                <path d="M 32 23 L 24 23 L 24 25 L 32 25 Z M 32 23 " />
                <path d="M 34 27 L 24 27 L 24 29 L 34 29 Z M 34 27 " />
                <path d="M 34 31 L 24 31 L 24 33 L 34 33 Z M 34 31 " />
            </g>
        </svg>
    )
}