import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { socialIconList } from '../../utitlities/IconRenderMap'
import clsx from 'clsx'
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({

    whatappPopUp: {
        position: 'fixed',
        bottom: '15px',
        right: '100px',
    }
}))

export default function WhatsappPopUpRender({ whatsAppNo }) {
    const classes = useStyles()
    let whatsapplogo = "../../images/whatsapp.png"
    return <div className={classes.whatappPopUp}>
        {
            whatsAppNo ?
            <a href={whatsAppNo.url} target="_blank">
                <StaticImage src={whatsapplogo} alt="whatsApp" className={classes.whatsapplogo} width={60}
      height={60}/>
            </a>
            : ''
        }
    </div>
}