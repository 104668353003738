import React from 'react'
import { Drawer, Grid, makeStyles, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import LinkComponent from './Gatsby/LinkComponent'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: "90%",
        maxWidth: "450px"
    },
    image: {
        maxWidth: "90px"
    },
    toolbar: {
        minHeight: "80px"
    },
    icon: {
        padding: "0px",
        margin: '0px'
    },
    accordionSummary: {
        padding: '0px',
        minHeight: "0px !important",
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600
    },
    accordionSummaryExpand: {
        minHeight: "0px !important",
    },
    summaryContent: {
        margin: '0px !important'
    },
    accordionDetails: {
        padding: '20px',
        // backgroundColor: theme.palette.primary.light
    }
}))

export default function DrawerComponent({
    open, onClose, AppLinks = []
}) {
    const classes = useStyles()
    return (
        <Drawer
            anchor='right'
            variant='temporary'
            open={open}
            onClose={onClose}
            PaperProps={{
                className: classes.drawerPaper
            }}
        >
            <div style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                            <IconButton id='close' onClick={onClose}>
                                <CloseIcon color='primary' />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {
                        AppLinks.map(link => {
                            const { id } = link
                            return <Grid item key={id} xs={12}>
                                {
                                    (!!link.subRoutes && !!link.subRoutes.links && link.subRoutes.links.length !== 0) ? <Accordion elevation={0}>
                                        <AccordionSummary classes={{
                                            root: classes.accordionSummary,
                                            content: classes.summaryContent,
                                            expanded: classes.accordionSummaryExpand,
                                            expandIcon: classes.icon
                                        }}
                                            expandIcon={<ExpandIcon color='primary' />}
                                        >{link.title}</AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                                            <Grid container spacing={2}>
                                                {
                                                    link.subRoutes.links.map(item => {
                                                        const route = `${!!link.subRoutes.linkPrefix ? `/${link.subRoutes.linkPrefix}/` : '/services/'}${item.slug}${!!link.subRoutes.linkPrefix ? `/` : ''}`
                                                        return <Grid item key={item.id} xs={12}>
                                                            <LinkComponent {...item} labelComponent='p'
                                                                route={route}
                                                                style={{
                                                                    fontSize: '0.75rem'
                                                                }} />
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion> :
                                        link.title === "Free Marketing Training" ?
                                            <div style={{ display: 'flex' }}>
                                                <LinkComponent showAsButton={true} {...link} labelComponent='p' />
                                            </div>
                                            :
                                            <LinkComponent  {...link} labelComponent='p' />
                                }
                            </Grid>
                        })
                    }
                </Grid>
            </div>
        </Drawer>
    )
}
