import React from 'react'

export default function GooglePartner() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1268 476" style={{ backgroundColor: 'white' }}>
            <style type="text/css">
                {`.stg0{fill:#557DBF;}
                .stg1{fill:#767676;}
                .stg2{fill:#557EBF;}
                .stg3{fill:#36A852;}
                .stg4{fill:#F9BC17;}
                .stg5{fill:#EA4535;}`}
            </style>
            <path class="stg0" d="M110.55,0.12c-0.06,1.66-0.19,3.32-0.19,4.98c-0.01,155.27-0.01,310.54,0,465.81c0,1.66,0.12,3.32,0.19,4.98
            c-36.66,0-73.31,0-109.97,0c-0.95-1.9-0.46-3.93-0.46-5.89C0.1,324.88,0.1,179.76,0.1,34.65c0-3.22,0-6.44,0-9.67
            C0.38,16.7-0.49,8.39,0.58,0.12C37.24,0.12,73.9,0.12,110.55,0.12z"/>
            <path class="stg1" d="M632.76,283.31c7.37-8.71,15.99-14.77,26.73-17.25c29.31-6.78,52.96,11.21,53.77,41.42
            c0.64,23.97,0.15,47.97,0.3,71.95c0.02,2.97-1.06,3.66-3.77,3.55c-4.49-0.18-9.01-0.29-13.49,0.02c-3.68,0.26-4.36-1.25-4.33-4.57
            c0.14-19.82,0.1-39.64,0.05-59.47c-0.01-5.66-0.42-11.32-1.96-16.8c-6.03-21.36-34.05-21.22-44.65-10.51
            c-8.12,8.2-11.6,18.03-11.63,29.29c-0.05,18.82-0.19,37.65,0.11,56.47c0.08,4.77-1.47,6.02-5.86,5.59c-3.47-0.34-7.02-0.33-10.48,0
            c-4,0.38-5.37-0.68-5.34-5.04c0.19-34.81,0.17-69.63,0.02-104.44c-0.02-3.96,1.09-5.34,5.01-4.94c2.97,0.3,5.99,0.06,8.99,0.06
            c6.54,0,6.54,0,6.55,6.32C632.76,277.74,632.76,280.52,632.76,283.31z"/>
            <path class="stg1" d="M541.08,323.46c0-10.49-0.09-20.98,0.05-31.46c0.04-3.05-0.95-4.13-4.01-3.96c-3.98,0.22-8-0.08-11.98,0.1
            c-2.86,0.13-4.37-0.49-4.1-3.79c0.41-4.95-1.95-11.28,0.87-14.53c2.93-3.38,9.42-1.16,14.31-1.05c4.13,0.09,5.05-1.38,4.94-5.18
            c-0.24-8.65,0.03-17.31-0.13-25.97c-0.06-3.01,0.85-4.17,3.96-4.02c4.65,0.22,9.33,0.2,13.98,0c2.92-0.12,3.67,1.03,3.63,3.77
            c-0.14,8.32,0.24,16.66-0.16,24.96c-0.23,4.87,0.85,6.88,6.18,6.42c6.12-0.52,12.31-0.03,18.47-0.16c2.53-0.05,3.61,0.72,3.48,3.39
            c-0.25,5.13,1.59,11.63-0.9,15.02c-2.43,3.3-9.32,0.98-14.23,1.04c-12.89,0.16-12.9,0.05-12.9,12.93c0,14.48-0.01,28.97,0.01,43.45
            c0,1.99,0.05,4.01,0.33,5.97c1.66,11.67,8.97,16.82,20.48,13.87c3.86-0.99,5.27-0.02,6.36,3.46c1.19,3.79,2.78,7.48,4.5,11.06
            c1.26,2.62,0.15,3.5-2.13,4.08c-10.75,2.77-21.49,2.92-31.85-1.28c-12.06-4.88-17.89-14.72-18.72-27.2
            c-0.69-10.27-0.14-20.62-0.14-30.94C541.3,323.46,541.19,323.46,541.08,323.46z"/>
            <path class="stg2" d="M270.3,121.25c5.99,0,12,0.27,17.98-0.09c4.05-0.24,5.42,1,5.62,5.1c0.99,20.18-5.41,36.87-24.19,46
            c-19.59,9.52-38.97,7.26-56.27-6.2c-22.09-17.18-25.66-49.07-8.43-70.9c17.61-22.3,49.82-26.72,71.84-9.84
            c4.83,3.7,4.83,3.7,0.62,7.91c-5.53,5.53-5.53,5.53-12.37,1.26c-12.48-7.77-28.68-6.95-40.82,2.08
            c-12.17,9.05-17.56,25.24-13.36,40.11c4.02,14.24,16.41,24.93,30.64,26.45c15.32,1.63,28.38-4.35,34.52-15.79
            c5.46-10.18,4.74-11.48-6.35-11.48c-6.66,0-13.32-0.07-19.98,0.03c-2.49,0.04-3.46-0.72-3.61-3.37
            c-0.66-11.27-0.75-11.27,10.68-11.27C261.3,121.25,265.8,121.25,270.3,121.25z"/>
            <path class="stg1" d="M884.34,286.26c6.13-10.92,15.47-16.98,27.09-19.69c6.56-1.53,13.23-1.03,19.75,0.75
            c2.04,0.56,3.68,1.09,2.26,4.02c-2.24,4.63-4.1,9.45-5.85,14.3c-0.88,2.45-2.15,2.69-4.5,2.3c-21.32-3.57-37.46,9.83-37.7,31.49
            c-0.21,19.64-0.15,39.28,0.04,58.92c0.04,3.74-0.92,5.03-4.68,4.64c-5.56-0.57-12.86,2.32-16.29-1.17
            c-3.19-3.23-0.85-10.45-0.87-15.9c-0.07-30.63,0.06-61.26-0.14-91.88c-0.03-4.48,1.22-5.88,5.59-5.47
            c3.96,0.37,7.99,0.21,11.98,0.04c2.66-0.12,3.46,0.91,3.37,3.47C884.22,276.79,884.34,281.53,884.34,286.26z"/>
            <path class="stg1" d="M460.46,286.32c6.42-11.83,16.57-17.55,29.06-19.95c14.41-2.77,15.72-1.74,15.72,12.57c0,0.33,0,0.67,0,1
            c0,7.07,0,7.21-7.12,7c-8.02-0.23-15.57,1.25-22.37,5.61c-9.8,6.28-14.24,15.5-14.27,26.95c-0.05,19.47-0.15,38.95,0.1,58.42
            c0.06,4.28-1.19,5.46-5.27,5.09c-3.96-0.36-8-0.33-11.97,0c-3.75,0.31-4.77-0.9-4.74-4.68c0.19-20.47,0.09-40.94,0.09-61.41
            c0-14.31,0.2-28.63-0.12-42.94c-0.1-4.58,1.38-5.81,5.65-5.41c3.63,0.34,7.34,0.31,10.97,0c3.66-0.31,4.54,1.18,4.34,4.55
            C460.26,277.5,460.46,281.91,460.46,286.32z"/>
            <path class="stg3" d="M529.44,127.08c0-14.82,0.1-29.65-0.08-44.47c-0.04-3.32,0.92-4.19,4.17-4.18c10.73,0,10.73-0.13,10.73,10.65
            c0,27.31-0.09,54.63,0.1,81.94c0.03,4.06-1.19,5-5.04,4.92c-9.87-0.21-9.87-0.03-9.87-9.89
            C529.44,153.06,529.44,140.07,529.44,127.08z"/>
            <path class="stg1" d="M301.17,243.4c-9.13-17.6-24.61-26.81-44.46-27.39c-18.3-0.53-36.62,0-54.93-0.2c-3.81-0.04-4.71,1.1-4.69,4.78
            c0.15,26.3,0.08,52.59,0.08,78.89c0,26.13,0.1,52.26-0.1,78.39c-0.03,4.25,1.2,5.5,5.3,5.14c3.96-0.35,8-0.32,11.97-0.01
            c3.61,0.29,4.4-1.07,4.37-4.48c-0.16-18.81-0.11-37.62-0.06-56.42c0.02-8-1.02-6.96,6.76-7c10.15-0.06,20.31,0.11,30.46-0.04
            c17.91-0.26,32.35-7.85,42.36-22.44C308.88,277.09,309.76,259.95,301.17,243.4z M259.54,294.03c-12.59,1.34-25.24,0.28-37.86,0.61
            c-2.97,0.08-3.07-1.64-3.06-3.84c0.06-8.48,0.02-16.95,0.02-25.43c0-8.48,0.07-16.96-0.04-25.43c-0.03-2.47,0.61-3.68,3.32-3.6
            c12.45,0.36,24.93-0.71,37.36,0.62c13.7,1.46,24.99,14.33,25.1,28.43C284.5,279.32,273.14,292.58,259.54,294.03z"/>
            <path class="stg1" d="M415.33,308.98c-0.43-16.86-8.25-30.09-23.55-37.88c-16.32-8.32-33.36-8.68-50.24-1.93
            c-9.7,3.88-16.79,11.21-21.91,20.34c-0.95,1.69-1.75,3.16,0.95,4.21c5.11,2,10.13,4.22,15.16,6.42c1.38,0.6,2.18,0.78,3.12-0.9
            c7.06-12.65,18.54-15.81,31.9-14.22c15.19,1.82,25.83,15.17,23.74,29.96c-9.1-4.82-18.87-6.34-28.89-6.54
            c-13.62-0.27-26.44,2.42-37.62,10.75c-18.17,13.55-19.18,41.91-2.03,56.75c16.58,14.35,44.34,14.39,60.7-0.06
            c2.8-2.48,5.08-5.54,8.48-9.29c0,4.83,0.16,8.6-0.05,12.34c-0.16,2.92,0.59,4.25,3.83,4.04c4.31-0.27,8.66-0.2,12.99-0.02
            c2.72,0.12,3.77-0.6,3.75-3.56C415.5,355.94,415.93,332.45,415.33,308.98z M393.88,341.24c-4.15,14.51-18.59,25.42-33.85,25.58
            c-4.08,0.24-7.95-0.76-11.64-2.31c-14.11-5.94-16.1-22.13-3.95-31.54c5.17-4,11.11-5.71,17.55-6.36c9.08-0.92,17.93-0.24,26.42,3.24
            C395.06,332.57,395.84,334.4,393.88,341.24z"/>
            <path class="stg1" d="M842.34,307.79c-7.76-39.7-57.3-57.27-88.24-31.11c-18.24,15.41-23.97,35.76-21.35,58.84
            c1.72,15.15,8.25,28.03,19.93,37.97c14.64,12.46,31.83,15.07,50.17,11.81c16.51-2.93,28.29-12.47,36.25-27.05
            c1.22-2.23,1.05-3.38-1.49-4.31c-4.37-1.59-8.73-3.29-12.92-5.3c-2.8-1.35-4.03-0.62-5.34,2.01c-3.49,7.02-9.09,11.78-16.56,14.26
            c-22.93,7.59-45.73-7.09-48.68-31.07c-0.45-3.7,0.68-4.17,3.83-4.14c13.49,0.12,26.98,0.05,40.48,0.05
            c13.83,0,27.65-0.08,41.48,0.06c3.19,0.03,4.41-0.82,4.34-4.19C844.1,319.6,843.49,313.67,842.34,307.79z M818.3,311.89
            c-20.8-0.09-41.6-0.05-62.73-0.05c5.62-24.13,28.88-33.46,49.75-23.51c8.22,3.92,12.89,11.27,15.16,20.06
            C820.97,310.28,821.39,311.9,818.3,311.89z"/>
            <path class="stg2" d="M515.44,122.1c0-8.37,0-8.4-8.44-8.34c-2.78,0.02-6.46-1.21-5.67,4.11c-17.01-9.51-31.68-7.26-42.09,6.09
            c-9.98,12.82-9.18,31.51,1.49,43.19c8.96,9.82,26.08,15.51,40.57,3.7c0,3.18,0.31,5.68-0.06,8.08c-1.09,7.08-4.37,12.58-11.85,14.46
            c-7.47,1.88-14.37,0.32-18.66-6.09c-2.97-4.45-5.54-4.79-9.54-2.38c-0.71,0.42-1.5,0.78-2.29,0.97c-3.87,0.92-4.14,2.71-2.14,6.02
            c9.01,14.92,29.85,20.11,44.68,10.89c10.47-6.5,13.77-16.76,13.93-28.23C515.62,157.08,515.44,139.59,515.44,122.1z M484.8,164.48
            c-10.05-0.18-18.23-9.26-18.08-20.07c0.16-10.9,8.5-19.66,18.59-19.52c9.88,0.13,17.2,8.68,17.14,20.01
            C502.39,156.06,494.65,164.65,484.8,164.48z"/>
            <path class="stg4" d="M410.44,111.35c-18.79,0.18-32.97,14.53-32.96,33.35c0.02,18.68,14.43,33.11,33.16,33.21
            c18.37,0.09,33.39-14.8,33.42-33.15C444.1,126.05,429.13,111.17,410.44,111.35z M410.64,164.27c-10.34,0.02-18.25-8.44-18.26-19.54
            c-0.02-11.39,7.92-19.86,18.53-19.77c10.19,0.08,18.31,8.92,18.18,19.77C428.94,155.72,420.89,164.25,410.64,164.27z"/>
            <path class="stg5" d="M335.67,111.66c-18.74-0.02-33.09,14.07-33.17,32.57c-0.08,19.02,14.56,33.73,33.41,33.57
            c18.46-0.16,32.86-14.61,32.92-33.04C368.89,125.87,354.68,111.68,335.67,111.66z M335.72,164.52
            c-10.15,0.08-18.34-8.64-18.44-19.61c-0.1-11.38,7.83-20.06,18.35-20.06c10.25-0.01,18.47,8.59,18.63,19.47
            C354.42,155.23,345.96,164.45,335.72,164.52z"/>
            <path class="stg5" d="M574.62,153.04c12.35-4.87,24.59-10.05,36.84-15.16c1.51-0.63,3.87-0.75,2.74-3.61
            c-3.81-9.65-9.44-17.67-19.72-21.15c-20.55-6.95-40.5,8.68-40.44,35.49c0.29,9.48,5.87,20,18.18,25.75
            c12.25,5.72,24.32,4.41,35.21-3.9c8.11-6.19,7.9-6.77-0.27-12.48c-3-2.1-4.57-2.08-7.23,0.78c-7.43,8.02-19.54,7.63-26.48-0.22
            C571.16,155.94,570.82,154.54,574.62,153.04z M568.55,143.37c0.12-7.91,3.3-13.36,9.56-16.79c5.28-2.9,10.74-3,15.97,0.47
            c1.41,0.93,3.01,2.11,3.02,3.7c0.01,1.7-2.17,1.7-3.45,2.24C585.49,136.43,577.28,139.77,568.55,143.37z"/>
        </svg>

    )
}